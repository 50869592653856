import TagManager from 'react-gtm-module';
import { getSearchParamFromUrl } from './urlParamaters';

export interface EventData {
    [key: string]: any;
}

declare global {
    interface Window {
        dataLayer: any[];
    }
}

let initialized = false;

const urlParams: { [key: string]: string | undefined } = {};

const initializeUrlParams = (): void => {
    try {
        const href = window.location.href;
        const asUrl = new URL(href);
        const params = new URLSearchParams(asUrl.search);

        params.forEach((_, key) => {
            const value = getSearchParamFromUrl(key);
            if (value !== undefined) {
                urlParams[key] = value;
            }
        });

        // console.log('Initialized URL parameters:', urlParams);
    } catch (error) {
        console.error('Failed to extract URL parameters:', error);
    }
};

export const init = (containerId: string): void => {
    if (!containerId) {
        console.error('GTM container ID is required for initialization.');
        return;
    }

    if (initialized) {
        console.warn('GTM is already initialized.');
        return;
    }

    TagManager.initialize({
        gtmId: containerId,
        dataLayer: {
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        },
    });

    initialized = true;

    initializeUrlParams();

    // console.log('GTM initialized with container ID:', containerId);
};

export const track = (eventName: string, eventData: EventData = {}): void => {
    if (!initialized) {
        console.warn('GTM is not initialized. Event not tracked.');
        return;
    }

    const customData = {
        event: eventName,
        ...urlParams,
        ...eventData,
    };

    window.dataLayer.push(customData);

    // console.log(`Tracking event: ${eventName}`, customData);
};

export const identify = (userId: string, userData: EventData = {}) => {
    if (!initialized) {
        console.warn('GTM is not initialized. User not identified.');
        return;
    }

    const customData = {
        event: 'identify',
        userId,
        ...urlParams,
        ...userData,
    };

    window.dataLayer.push(customData);

    // console.log(`User identified: ${userId}`, customData);
};

const gtmInstance = {
    init,
    track,
    identify,
};
export default gtmInstance;
