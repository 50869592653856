import mixPanel, { mixPanelEventsTypes } from './mixPanel';
import gtm, { EventData } from './gtm';
import config from 'config/common';

const extraCommonDetails = {
    version: config.version,
    namespace: config.namespace,
    siteName: config.siteName,
    siteType: config.siteType,
    isWrapperApp: false,
};

export enum AnalyticsType {
    MIXPANEL = 'mixPanel',
    GTM = 'gtm',
}

const analyticsTypes: AnalyticsOptions[] | undefined = [];

if (config.mixpanelKey && !config.featuresFlags['disableMixPanel']) {
    analyticsTypes.push({
        connectionId: config.mixpanelKey,
        handlerType: AnalyticsType.MIXPANEL,
        initialData: extraCommonDetails,
    });
}

if (config.gtmContainerId && !config.featuresFlags['disableGTM']) {
    analyticsTypes.push({
        connectionId: config.gtmContainerId,
        handlerType: AnalyticsType.GTM,
        initialData: extraCommonDetails,
    });
}

export interface AnalyticsOptions {
    handlerType: AnalyticsType;
    connectionId: string;
    initialData: Record<string, any>;
}
export interface AnalyticsManagerOptions {
    analyticsTypes?: AnalyticsOptions[];
}

export const analyticsEventHandlers = {
    [AnalyticsType.MIXPANEL]: mixPanel,
    [AnalyticsType.GTM]: gtm,
};

interface EventDataHandler {
    track: (type: mixPanelEventsTypes, payload?: any) => void;
    identify: (accountId: string) => void;
    setPeople?: (payload: {}) => void;
    init: (accountId: string) => void;
}

class AnalyticsManager {
    private _analyticsEventHandlers: {
        name: string;
        handler: EventDataHandler;
        initialData?: Record<string, any>;
    }[] = [];
    private _analyticsTypes: AnalyticsOptions[];

    constructor({ analyticsTypes = [] }: AnalyticsManagerOptions) {
        this._analyticsTypes = analyticsTypes;
        this.initializeAnalytics();
    }

    private initializeAnalytics(): void {
        this._analyticsTypes.forEach((analyticsType) => {
            analyticsEventHandlers[analyticsType.handlerType].init(analyticsType.connectionId);
            this._analyticsEventHandlers.push({
                name: analyticsType.handlerType,
                handler: analyticsEventHandlers[analyticsType.handlerType],
                initialData: analyticsType?.initialData,
            });
        });
    }

    async trackEventGeneric(eventName: mixPanelEventsTypes, eventData: EventData = {}): Promise<void> {
        // console.log('track ###', eventName, eventData);
        for (const { name, handler, initialData = {} } of this._analyticsEventHandlers) {
            try {
                handler.track(mixPanelEventsTypes.GENERIC_EVENT, {
                    event_name: eventName,
                    ...eventData,
                    ...initialData,
                });
            } catch (error) {
                console.error(`Failed to track event with ${name}:`, error);
            }
        }
    }

    async trackEvent(eventName: mixPanelEventsTypes, eventData: EventData = {}): Promise<void> {
        // console.log('track ###', eventName, eventData);
        for (const { name, handler, initialData = {} } of this._analyticsEventHandlers) {
            try {
                handler.track(eventName, { ...eventData, ...initialData });
            } catch (error) {
                console.error(`Failed to track event with ${name}:`, error);
            }
        }
    }

    async identify(accountId: string): Promise<void> {
        for (const { name, handler } of this._analyticsEventHandlers) {
            try {
                handler.identify(accountId);
            } catch (error) {
                console.error(`Failed to identify user with ${name}:`, error);
            }
        }
    }

    async setPeople(payload: {}): Promise<void> {
        for (const { name, handler } of this._analyticsEventHandlers) {
            try {
                if (handler.setPeople) {
                    handler.setPeople(payload);
                }
            } catch (error) {
                console.error(`Failed to set people with ${name}:`, error);
            }
        }
    }
}

const analyticsManager = new AnalyticsManager({
    analyticsTypes,
});
export { mixPanelEventsTypes };
export default analyticsManager;
