import * as React from 'react';
import MainRouter from 'scenes/MainRouter';
import { AppProps, mapDispatchToProps, mapStateToProps, RefContext } from 'App/app.scheme';
import useStyles from './app.styles';
import SideMenu from 'components/SideMenu';
import { CssBaseline, Hidden, ThemeProvider } from '@material-ui/core';
import createMuiTheme from 'muiTheme/theme/createMuiTheme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useTranslation } from 'react-i18next';
import { onChangeLng } from 'i18n';
import { getLanguageDirection, localesCodeMap } from 'config/i18n';
import { connect } from 'react-redux';
import config, { FeatureFlag } from 'config/common';
import SnackbarWrapper from './components/SnackbarWrapper';
import { useHistory } from 'react-router-dom';
import { mixPanelEventsTypes } from 'helpers/analyticsManager';
import Header from 'components/Header';
import moment from 'moment';
import MsgPopup from 'components/MsgPopup';
import WhatsAppButton from 'components/WhatsAppButton/whatsAppButton.render';
import { getAllSearchParamsAsObject, getSearchParamFromUrl } from 'helpers/urlParamaters';
import i18n from 'i18next';
import site24x7 from 'services/site24x7';
import MobileBottomNavigation from '../components/BottomNavigation';
import { Pages } from 'interfaces/main';
import { isTablet } from 'helpers/device_helper';
import Disclaimer from './components/Disclaimer';
import DisclaimerEu from './components/Disclaimer-eu';
import LogRocket from 'logrocket';
import useDeviceType from 'hooks/useDeviceType';
import VersionPopup from './components/VersionPopup/version-popup.render';
import ChatDrawer from 'components/ChatDrawer/chat-drawer.render';
import useDisableBottomNavOnNewChallenge from 'components/BottomNavigation/useDisableBottomNavOnNewChallenge';
import { useSetDepositResults } from 'features/prop/hooks/useSetDepositResults';
import TradersEducationDialog from 'features/prop/scenes/Checkout/components/TradersEducationDialog/tradersEducationDialog.render';
import FundedInvestingManualKycDialogWithStoreConnection from 'features/prop/scenes/MyChallenges/components/FundedInvestingManualKycDialog/fundedInvestingManualKycDialogWithStoreConnection';
import ChallengeRetryDialog from 'features/prop/scenes/MyChallenges/components/ChallengeRetryDialog/challengeRetryDialog.render';
import ChallengeRetryDisabledDialog from 'features/prop/scenes/MyChallenges/components/ChallengeRetryDisabledDialog/challengeRetryDisabledDialog.render';
import SplashLoaderFullScreen from 'components/SpalshLoaderFullScreen';
import IntercomChat from 'components/IntercomChat/IntercomChat.render';
import LoaderFullScreen from 'components/LoaderFullScreen';
import analyticsManager from 'helpers/analyticsManager';
import { useCurrentUrl } from 'hooks/useCurrentUrl';
import usePreviousPathname from 'hooks/usePreviousPathname';

const App: React.FunctionComponent<AppProps> = (props) => {
    const {
        selectedChallenge,
        hasJwtToken,
        loggedIn,
        systemLoader,
        systemTheme,
        user_getUserData,
        userVerified,
        ipData,
        auth_getIpData,
        countryLanguages,
        system_side_menu_state,
        system_deviceTypeSize,
        prop__myPlans_getPlans,
        getNewGoal,
        mainTpAccount,
        getPaymentTypes,
        getPromoDiscountCodes,
        payment_resetState,
        payment_setDepositType,
        payment_setDepositResult,
        depositType,
        system_getCountries,
        system_toggleEducationModal,
        prop_getAdditionalInfoFromMonetaryTransactions,
        educationModalState,
        platform_getMteIframeUrl,
    } = props;
    const { listen, location } = useHistory();
    const currentUrl = useCurrentUrl();
    const previousPathname = usePreviousPathname();

    const useDisableBottomNav = useDisableBottomNavOnNewChallenge();
    const scrollToTopElement = React.useRef<any>(null);
    const deviceType = useDeviceType();
    const myRef = React.useRef<HTMLElement>(null);

    const {
        i18n: { language },
    } = useTranslation();

    const [appLanguage, setAppLanguage] = React.useState<string>(language);
    const [splashScreenOn, setSplashScreenOn] = React.useState(true);

    React.useEffect(() => {
        if (!systemLoader) {
            setSplashScreenOn(false);
        }
    }, [systemLoader]);

    const classes = useStyles({
        theme: systemTheme,
        loggedIn,
        fixedDisclaimerRow: config.theme.fixedDisclaimerRow,
        system_side_menu_state,
    });

    React.useEffect(() => {
        auth_getIpData();
        system_getCountries();
    }, [system_getCountries, auth_getIpData]);

    useSetDepositResults({
        depositType,
        payment_resetState,
        payment_setDepositType,
        payment_setDepositResult,
        depositSuccessRedirectUrl: `/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}/${Pages.PROP__NEW_CHALLENGES__DEPOSIT_SUCCESS}`,
    });

    React.useEffect(() => {
        user_getUserData();
    }, [user_getUserData, hasJwtToken]);

    React.useEffect(() => {
        site24x7.init();
    }, []);

    React.useEffect(() => {
        if (config.featuresFlags['mte'] && mainTpAccount?.id) platform_getMteIframeUrl(mainTpAccount.id);
    }, [mainTpAccount?.id]);

    React.useEffect(() => {
        if (config.featuresFlags[FeatureFlag.PROP] && loggedIn) {
            getNewGoal();
            prop__myPlans_getPlans();
            getPromoDiscountCodes();
        }
    }, [getNewGoal, getPromoDiscountCodes, loggedIn, prop__myPlans_getPlans]);

    React.useEffect(() => {
        if (loggedIn && mainTpAccount?.id) {
            getPaymentTypes(mainTpAccount?.id || '');
            prop_getAdditionalInfoFromMonetaryTransactions(mainTpAccount?.id || '');
        }
    }, [getPaymentTypes, loggedIn, mainTpAccount?.id]);

    React.useEffect(() => {
        if (config?.logRocketId) {
            LogRocket.init(config.logRocketId);
        }
    }, []);

    React.useEffect(() => {
        const appVisibilityChange = (e) => {
            const lastVisible = localStorage.getItem('lastVisibleTime');
            if (document.visibilityState === 'visible' && Number(lastVisible) + 30000 < Date.now()) {
                window.parent.location.reload();
            } else {
                localStorage.setItem('lastVisibleTime', Date.now().toString());
            }
        };

        const isWrapperApp: string | undefined | null = getSearchParamFromUrl('app') || localStorage.getItem('app');
        if (isWrapperApp === 'ios' || isWrapperApp === 'android') {
            localStorage.setItem('app', isWrapperApp);
            document.addEventListener('visibilitychange', appVisibilityChange);
        }
        return () => {
            document.removeEventListener('visibilitychange', appVisibilityChange);
        };
    }, []);

    React.useEffect(() => {
        const searchParams = getAllSearchParamsAsObject();
        const filteredParams = {};

        Object.keys(searchParams).forEach((key) => {
            if (key.startsWith('mi_')) {
                const filteredKey = key.substring(3);
                filteredParams[filteredKey] = searchParams[key];
            }
        });

        if (Object.keys(filteredParams).length > 0) {
            const miParams = localStorage.getItem('appMiParams');
            const storedParams = miParams ? JSON.parse(miParams) : {};
            const mergedParams = { ...storedParams, ...filteredParams };
            localStorage.setItem('appMiParams', JSON.stringify(mergedParams));
        }
    }, []);

    React.useEffect(() => {
        onLanguageChange(language);
    }, [language]);

    React.useEffect(() => {
        system_deviceTypeSize(deviceType);
    }, [deviceType, system_deviceTypeSize]);

    // set language based on country ip
    React.useEffect(() => {
        if (!localStorage.getItem('countryLangRedirected') && !getSearchParamFromUrl('lang') && !loggedIn) {
            let countryLangs = countryLanguages || [];
            // let existsLang = countryLangs.find((lng)=> localesCodeMap.includes(lng)) || null;
            let existsLang = localesCodeMap.find((lng) => lng === countryLangs[0]);
            if (existsLang) {
                i18n.changeLanguage(existsLang);
                localStorage.setItem('countryLangRedirected', '1');
            }
        }
    }, [loggedIn, ipData, countryLanguages]);

    React.useEffect(() => {
        const iframePlaceholder = document.getElementById('platformIframePlaceholder');
        const iframe = document.getElementById('platformIframe');

        if (!loggedIn && iframe && iframePlaceholder) {
            iframePlaceholder['accountName'] = undefined;
            iframe.remove();
        }
    }, [loggedIn]);

    React.useEffect(() => {
        const historyListen = listen(({ pathname: pathname }) => {
            analyticsManager.trackEvent(mixPanelEventsTypes.PAGE_VIEW, {
                pageName: pathname,
                previousPage: previousPathname,
                url: currentUrl,
                platform: window.location.hostname,
            });
            setTimeout(() => {
                if (scrollToTopElement && scrollToTopElement.current) {
                    scrollToTopElement.current.scroll({ top: 0, left: 0, behavior: 'smooth' });
                }
            }, 100);
        });
        return () => {
            historyListen();
        };
    }, [listen, language, systemTheme, previousPathname]);

    React.useEffect(() => {
        analyticsManager.trackEvent(mixPanelEventsTypes.PAGE_VIEW, {
            pathname: location.pathname,
            url: currentUrl,
            platform: window.location.hostname,
        });
    }, []);

    //~~~React Effect Functions
    const onLanguageChange = (newLanguage: string) => {
        try {
            switch (newLanguage) {
                case 'en':
                    break;
                case 'cn':
                    require(`moment/locale/zh-cn`);
                    break;
                default:
                    require(`moment/locale/${newLanguage}`);
                    break;
            }
            moment.locale(newLanguage);
        } catch (error) {
            moment.locale('en');
        }
        onChangeLng(newLanguage);
        setAppLanguage(newLanguage); // force app to rebuild on language change
    };

    React.useEffect(() => {
        if (!systemLoader) {
            const windowAny = window as any;
            // IOS wrapper ready
            if (windowAny?.webkit?.messageHandlers?.callbackHandler?.postMessage) {
                windowAny.webkit.messageHandlers.callbackHandler.postMessage(
                    JSON.stringify({
                        action: 'showapp',
                    })
                );
            }

            // Android wrapper ready
            if (windowAny?.nativeWrapper?.showApp) {
                windowAny.nativeWrapper.showApp();
            }

            window.removeEventListener('online', windowAny.mainIndexOnline);
            window.removeEventListener('offline', windowAny.mainIndexOffline);
        }
    }, [systemLoader]);

    if (systemLoader) {
        return <LoaderFullScreen />;
    }

    return (
        <ThemeProvider key={appLanguage} theme={createMuiTheme(systemTheme, getLanguageDirection(language))}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
                {splashScreenOn && <SplashLoaderFullScreen withoutLoader />}
                <>
                    <CssBaseline />

                    <div className={classes.app_container}>
                        {loggedIn && userVerified && (
                            <div className={classes.side_menu}>
                                <SideMenu />
                            </div>
                        )}
                        <div className={classes.body} ref={scrollToTopElement}>
                            <Header disabled={true} />
                            <main ref={myRef} className={classes.main}>
                                <RefContext.Provider value={myRef}>
                                    <MainRouter />
                                </RefContext.Provider>
                            </main>
                            {loggedIn && !useDisableBottomNav && (
                                <Hidden smUp>
                                    <div className={classes.bottomNavigationWrapper}>
                                        <MobileBottomNavigation />
                                    </div>
                                </Hidden>
                            )}
                            {!loggedIn && config.featuresFlags['disclaimer'] && <Disclaimer />}
                            {!loggedIn &&
                                !config.featuresFlags['disclaimer'] &&
                                config.featuresFlags['disclaimer-eu'] && <DisclaimerEu />}
                        </div>
                    </div>

                    <VersionPopup />

                    {loggedIn && userVerified && !isTablet() && <MsgPopup imageOnly />}

                    <WhatsAppButton />
                    <SnackbarWrapper />
                    <TradersEducationDialog onClose={system_toggleEducationModal} isOpen={educationModalState} />
                    <FundedInvestingManualKycDialogWithStoreConnection />
                    <ChallengeRetryDialog key={`ChallengeRetryDialog_${selectedChallenge?.tpName}`} />
                    <ChallengeRetryDisabledDialog key={`ChallengeRetryDisabledDialog_${selectedChallenge?.tpName}`} />
                </>
                {!!config['intercomAppId'] && !!config.featuresFlags['intercom'] && <IntercomChat />}
            </MuiPickersUtilsProvider>
            {loggedIn && !!config['reactAppChatBaseKey'] && <ChatDrawer />}
        </ThemeProvider>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(App));
