import React, { useCallback } from 'react';
import { IconsType } from 'components/Icon';
import Dialog from 'components/Dialog';
import useStyles from './challengeRetryDialog.styles';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'components/Button';
import Typography from 'components/Typography';
import IconBox from 'components/IconBox/iconBox.render';
import ChallengeRetryTime from '../TimeBox/timeBox.render';
import { Pages } from 'interfaces/main';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { ChallengeRetryDialogProps, mapDispatchToProps, mapStateToProps } from './challengeRetryDialog.scheme';
import { useEnableRetryButton } from 'features/prop/hooks/useEnableRetryButton';
import useRedirectPlanRetryToCheckout from 'hooks/useRedirectPlanRetryToCheckout';
import { RetryParamsRetryDiscountTypeEnum } from 'services/generatedClientFromSwagger/models/retry-params';
import { useRetryDetails } from 'features/prop/hooks/useEnableRetryLogicMixpanel';
import { mixPanelEventsTypes } from 'helpers/mixPanel';
import analyticsManager from 'helpers/analyticsManager';

const challengeMemoryKey = 'retryDialog';
const ChallengeRetryDialog: React.FunctionComponent<ChallengeRetryDialogProps> = (props) => {
    const { selectedChallenge: challenge, selectedChallengePlan, selectedTpAccount } = props;
    const counter = +(localStorage.getItem(`${challengeMemoryKey}_${challenge?.id}`) ?? 0);
    const { handleSelectedPlanRedirectToCheckout } = useRedirectPlanRetryToCheckout();
    const { gapInMilliseconds, isRetryActive } = useEnableRetryButton();
    const mixPanelData = useRetryDetails(selectedChallengePlan?.pPlan?.planId ?? '');
    const [isOpen, setIsOpen] = React.useState(true);

    const classes = useStyles();
    const { t } = useTranslation('prop');
    const history = useHistory();

    const handleBackButton = useCallback(() => {
        analyticsManager.trackEvent(mixPanelEventsTypes.PROP_RETRY_MODAL_RETRY_BACK, mixPanelData);
        history.push(`/${Pages.PROP}/${Pages.PROP__MY_CHALLENGES}`);
        setIsOpen(false);
    }, [history, mixPanelData]);

    const handleRetryButton = useCallback(() => {
        analyticsManager.trackEvent(mixPanelEventsTypes.PROP_RETRY_MODAL_RETRY_NOW, mixPanelData);
        localStorage.setItem(
            `${challengeMemoryKey}_${challenge?.id}`,
            `${+(localStorage.getItem(`${challengeMemoryKey}_${challenge?.id}`) ?? 0) + 1}`
        );
        handleSelectedPlanRedirectToCheckout();
        setIsOpen(false);
    }, [challenge?.id, handleSelectedPlanRedirectToCheckout, mixPanelData]);

    const handleClose = useCallback(() => {
        localStorage.setItem(
            `${challengeMemoryKey}_${challenge?.id}`,
            `${+(localStorage.getItem(`${challengeMemoryKey}_${challenge?.id}`) ?? 0) + 1}`
        );

        analyticsManager.trackEvent(mixPanelEventsTypes.PROP_RETRY_MODAL_RETRY_CLOSE, mixPanelData);
        setIsOpen(false);
    }, [challenge?.id, mixPanelData]);

    return (
        <Dialog
            eventLogName="Challenge retry try again"
            open={!!(isRetryActive && isOpen && counter < 1)}
            onClose={handleClose}
            classes={{ paper: classes.dialogPaper, root: classes.backDrop }}
            closeButtonIcon={{ icon: IconsType.x_close, className: classes.closeIcon }}
        >
            <div className={classes.iconsContainer}>
                <IconBox iconType={IconsType.refresh_icon} color={'success'} variant={'border'} size={48} />
            </div>
            <Typography className={classes.title} variant="tLgSemibold" component="h2">
                {t('challengeRetryDialog.title')}
            </Typography>
            <Typography className={classes.wrapperId} variant="tSmallSemibold">
                <div className={classes.id}>{t('challengeRetryDisabledDialog.id')}</div>
                <div>{challenge?.tpName}</div>
            </Typography>

            <Typography variant="tSmallRegular" className={classes.description}>
                <Trans
                    i18nKey="prop:challengeRetryDialog.description"
                    values={{
                        discount: selectedChallengePlan?.pPlan?.retryOptions?.retryDiscountAmount,
                        symbol:
                            selectedChallengePlan?.pPlan?.retryOptions?.retryDiscountType ===
                            RetryParamsRetryDiscountTypeEnum.Fixed
                                ? selectedTpAccount?.baseCurrency?.symbol
                                : '',
                        percentage:
                            selectedChallengePlan?.pPlan?.retryOptions?.retryDiscountType ===
                            RetryParamsRetryDiscountTypeEnum.Percent
                                ? '%'
                                : '',
                    }}
                    components={{
                        discount: <span className={classes.discount}></span>,
                    }}
                />
            </Typography>
            <div>
                <ChallengeRetryTime gapInMilliseconds={gapInMilliseconds} />
            </div>
            <div className={classes.buttonGroup}>
                <Button className={classes.backButton} variant="outlined" color="inherit" onClick={handleBackButton}>
                    <Typography className={classes.backBtnText} variant="tMdSemibold">
                        {t('challengeRetryDialog.backButton')}
                    </Typography>
                </Button>

                <Button className={classes.button} variant="contained" color="primary" onClick={handleRetryButton}>
                    {t('challengeRetryDialog.retryButton')}
                </Button>
            </div>
        </Dialog>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ChallengeRetryDialog));
