import { Hidden } from '@material-ui/core';
import { connect } from 'react-redux';
import React from 'react';
import Logo from '../components/Logo';
import { PrivateHeaderProps, mapDispatchToProps, mapStateToProps } from './private-header.scheme';
import useStyles from '../header.styles';
import BackOrMenuBtn from 'components/BackOrMenuBtn';
import TpSelect from 'components/TpSelect';
import { headerComponentBtn } from 'features/Notifications';
import { useLocation } from 'react-router-dom';
import NetworkConnectionMessage from 'components/NetworkConnectionMessage';
import { mixPanelEventsTypes } from 'helpers/mixPanel';
import ChallengeHeaderSelect from 'features/prop/components/ChallengeHeaderSelect';
import config from 'config/common';
import { Pages } from 'interfaces/main';
import StartNewChallengeBtn from 'features/prop/components/StartNewChallengeBtn';
import { useHideStartChallengeButton } from 'features/prop/hooks/useHideStartChallengeButton';
import RetryChallengeBtn from 'features/prop/components/RetryChallengeBtn';
import { useTranslation } from 'react-i18next';
import WtTradeBtn from 'features/prop/components/WtTradeBtn';
import { useDisplayTradeNowButton } from 'features/prop/hooks/useDisplayTradeNowButton';
import { useEnableRetryButton } from 'features/prop/hooks/useEnableRetryButton';
import { useValidChallenges } from 'features/prop/hooks/useValidChallenges';
import LanguageSelect from 'components/SideMenu/components/LanguageSelect';
import analyticsManager from 'helpers/analyticsManager';

const PrivateHeader: React.FunctionComponent<PrivateHeaderProps> = (props) => {
    const { user_accountType, system_toggleSideMenu, user_fullName, system_selectorDeviceTypeSize, menuState } = props;
    const classes = useStyles();
    const location = useLocation();
    const { t } = useTranslation('prop');
    const hideStartChallengeButton = useHideStartChallengeButton();
    const displayTradeNowButton = useDisplayTradeNowButton();
    const [networkConnectionStateSelector, networkConnectionState] = React.useState<boolean>(true);
    const showAccountType = React.useMemo(() => {
        if (config.featuresFlags['prop'] || config.featuresFlags['ccs']) {
            return false;
        }
        return true;
    }, []);
    const { isRetryActive: enableRetryButton } = useEnableRetryButton();

    React.useEffect(() => {
        const networkDisconnected = (status: boolean) => {
            analyticsManager.trackEvent(mixPanelEventsTypes.NETWORK_CONNECTION, { connectionStatus: 'disconnected' });
            networkConnectionState(status);
        };
        const networkConnected = (status: boolean) => {
            analyticsManager.trackEvent(mixPanelEventsTypes.NETWORK_CONNECTION, { connectionStatus: 'connected' });
            networkConnectionState(status);
        };
        window.addEventListener('online', () => networkConnected(window.navigator.onLine));
        window.addEventListener('offline', () => networkDisconnected(window.navigator.onLine));
    }, []);

    const isPlatformPage = location.pathname.includes(`/${Pages.PLATFORMS}`);

    const validChallenges = useValidChallenges();

    return (
        <div>
            <React.Fragment>
                {!networkConnectionStateSelector && <NetworkConnectionMessage />}
                <header className={classes.header_container__private}>
                    <div className={classes.header_left_section}>
                        <Hidden smUp>
                            <BackOrMenuBtn
                                deviceSize={system_selectorDeviceTypeSize}
                                accountName={user_fullName}
                                toggleSideMenu={system_toggleSideMenu}
                            />
                        </Hidden>
                        <Logo isPrivate={true} id="mainLogo" />
                        {showAccountType && <TpSelect accountType={user_accountType} />}
                        {config.featuresFlags['prop'] && isPlatformPage && (
                            <ChallengeHeaderSelect challenges={validChallenges} />
                        )}
                        <Hidden xsDown>
                            <div />
                        </Hidden>
                        <Hidden smUp>{config.featuresFlags['prop'] && <>{headerComponentBtn()}</>}</Hidden>
                    </div>
                    <div className={classes.header_right_section}>
                        <Hidden xsDown>
                            {!config.featuresFlags['prop'] && <LanguageSelect menuState={menuState} />}
                        </Hidden>
                        {config.featuresFlags['prop'] && (
                            <Hidden xsDown>
                                {enableRetryButton && isPlatformPage && <RetryChallengeBtn />}
                                {displayTradeNowButton && <WtTradeBtn />}
                                {!hideStartChallengeButton && <StartNewChallengeBtn label={t('prop_btn')} />}
                            </Hidden>
                        )}
                        <Hidden xsDown>
                            {config.featuresFlags['prop'] && <LanguageSelect menuState={menuState} />}
                        </Hidden>
                        <Hidden xsDown>{config.featuresFlags['prop'] && <>{headerComponentBtn()}</>}</Hidden>
                    </div>
                </header>
            </React.Fragment>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PrivateHeader));
