import React, { FunctionComponent } from 'react';
import DropZone from 'components/Inputs/DropZone';
import Typography from 'components/Typography';
import { Link, createStyles, makeStyles } from '@material-ui/core';
import { UploadedFile } from 'components/Inputs/DropZone/dropzone.render';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) =>
    createStyles({
        link: {
            cursor: 'pointer',
            color: theme.palette.primaryVariants[600],
        },
        proof: {
            color: theme.palette.grayVariants[700],
            textWrap: 'nowrap',
        },
        limit: {
            color: theme.palette.grayVariants[500],
        },
        container: {
            textAlign: 'center',
        },
    })
);

interface CheckoutDropZoneProps {
    onDrop: (files: UploadedFile[]) => void;
    uploadHighlight: string;
    uploadDisclaimer: string;
    uploadAllowedFiles: string;
}

const acceptedFiles = ['.jpg', '.png', '.pdf'];

const CheckoutDropZone: FunctionComponent<CheckoutDropZoneProps> = ({
    onDrop,
    uploadHighlight,
    uploadDisclaimer,
    uploadAllowedFiles,
}) => {
    const classes = useStyles();
    const { t } = useTranslation('prop');

    return (
        <div>
            <DropZone
                onDrop={onDrop}
                accept={acceptedFiles}
                dragHereText={uploadHighlight}
                browseText={uploadDisclaimer}
            >
                <div className={classes.container}>
                    <Typography variant="tSmallMedium">
                        <Typography component={'span'} variant="tSmallMedium">
                            <Link className={classes.link} underline="none">
                                {uploadHighlight}{' '}
                            </Link>
                        </Typography>
                        <Typography component="span" className={classes.proof} variant="tSmallMedium">
                            {uploadDisclaimer}
                        </Typography>
                    </Typography>
                    <Typography className={classes.limit} variant="tXsRegular">
                        {uploadAllowedFiles}
                    </Typography>
                </div>
            </DropZone>
        </div>
    );
};

export default CheckoutDropZone;
