import { ESystemTypes } from 'store/system/actionsTypes';
import { EPropMyChallengesTypes } from './actionsTypes';
import { PropMyPlansReducerState } from './interfaces';
import { AdditionalInfo } from 'services/generatedClientFromSwagger';

const initialState: PropMyPlansReducerState = {
    challengesLoader: true,
    challenges: [],
    plans: [],
    filteredChallenges: [],
    onlyFundedChallenges: [],
    fundedPropChallenge: [],
    challengeIframeLoading: false,
    challengeIframe: [],
    challengeIframeError: undefined,
    selectedChallenge: undefined,
    selectedChallengePlan: undefined,
    mainAccountTp: undefined,
    challengeIframePopup: false,
    setFundedChallengeLoader: false,
    fundsFilterOptions: [],
    validateWithdrawalData: undefined,
    isKycDialogOpen: undefined,
    manualKycLoading: false,
    isStartFundedAlertOpen: {},
    additionalInfoFromMonetaryTransactions: [],
    takenRetries: {},
};

const PropMyPlansReducer = (
    state = initialState,
    { type, payload }: { type: EPropMyChallengesTypes | ESystemTypes; payload: any }
) => {
    switch (type) {
        case EPropMyChallengesTypes.SET_KYC_DIALOG_STATE: {
            return {
                ...state,
                isKycDialogOpen: payload,
            };
        }

        case EPropMyChallengesTypes.GET_PLANS_START:
            return {
                ...state,
            };
        case EPropMyChallengesTypes.GET_PLANS_SUCCESS:
            return {
                ...state,
                plans: payload,
            };
        case EPropMyChallengesTypes.GET_PLANS_FAIL:
            return {
                ...state,
            };
        case EPropMyChallengesTypes.SET_FUNDED_ALERT_TOGGLE:
            const isStartFundedAlertOpen = {
                ...state.isStartFundedAlertOpen,
                ...payload,
            };
            return {
                ...state,
                isStartFundedAlertOpen,
            };
        case EPropMyChallengesTypes.GET_CHALLENGES_START:
            return {
                ...state,
                challengesLoader: payload.withLoading,
            };
        case EPropMyChallengesTypes.GET_CHALLENGES_SUCCESS:
            return {
                ...state,
                challengesLoader: false,
                challenges: payload.challenges,
                filteredChallenges: payload.filteredChallenges,
                onlyFundedChallenges: payload.onlyFundedChallenges,
                fundsFilterOptions: payload.fundsFilterOptions,
            };
        case EPropMyChallengesTypes.SET_FUNDED_CHALLENGES_START:
            return {
                ...state,
                setFundedChallengeLoader: true,
            };
        case EPropMyChallengesTypes.SET_FUNDED_CHALLENGES_SUCCESS:
            return {
                ...state,
                setFundedChallengeLoader: false,
                fundedPropChallenge: payload.fundedPropChallenge,
            };
        case EPropMyChallengesTypes.SET_FUNDED_CHALLENGES_FAIL:
            return {
                ...state,
                setFundedChallengeLoader: false,
            };
        case EPropMyChallengesTypes.GET_CHALLENGES_FAIL:
            return {
                ...state,
                challengesLoader: false,
            };
        case EPropMyChallengesTypes.GET_CHALLENGE_IFRAME_START: {
            return {
                ...state,
                challengeIframeLoading: true,
                challengeIframeError: undefined,
            };
        }
        case EPropMyChallengesTypes.GET_CHALLENGE_IFRAME_SUCCESS: {
            return {
                ...state,
                challengeIframeLoading: false,
                challengeIframe: {
                    ...state.challengeIframe,
                    [payload.tpId]: payload.iframeUrl,
                },
            };
        }
        case EPropMyChallengesTypes.GET_CHALLENGE_IFRAME_FAIL: {
            return {
                ...state,
                challengeIframeLoading: false,
                challengeIframeError: payload,
            };
        }

        case EPropMyChallengesTypes.SET_SELECTED_CHALLENGE: {
            return {
                ...state,
                selectedChallenge: payload.selectedChallenge,
                selectedChallengePlan: payload.selectedChallengePlan,
            };
        }

        case EPropMyChallengesTypes.SET_MAIN_ACCOUNT_TP: {
            return {
                ...state,
                mainAccountTp: payload,
            };
        }
        case EPropMyChallengesTypes.GET_CHALLENGE_IFRAME_POPUP_START: {
            return {
                ...state,
                challengeIframePopup: false,
            };
        }
        case EPropMyChallengesTypes.GET_CHALLENGE_IFRAME_POPUP_SUCCESS: {
            return {
                ...state,
                challengeIframePopup: true,
            };
        }
        case EPropMyChallengesTypes.GET_CHALLENGE_IFRAME_POPUP_FAIL: {
            return {
                ...state,
                challengeIframePopup: false,
            };
        }

        case EPropMyChallengesTypes.ACCEPT_CHALLENGE_TERMS_SUCCESS: {
            return {
                ...state,
                challenges: state.challenges.map((challenge) => {
                    if (challenge.id === payload.challengeId) {
                        return {
                            ...challenge,
                            acceptTermsAndConditions: payload.acceptTermsAndConditions,
                        };
                    }
                    return challenge;
                }),
            };
        }
        case EPropMyChallengesTypes.VALIDATE_WITHDRAWAL_SUCCESS: {
            return {
                ...state,
                validateWithdrawalData: {
                    ...state.validateWithdrawalData,
                    [payload.tpAccount]: payload.data,
                },
            };
        }
        case EPropMyChallengesTypes.STATUS_MANUAL_KYC_START: {
            return {
                ...state,
                manualKycLoading: true,
            };
        }
        case EPropMyChallengesTypes.STATUS_MANUAL_KYC_SUCCESS: {
            return {
                ...state,
                manualKycLoading: false,
            };
        }
        case EPropMyChallengesTypes.STATUS_MANUAL_KYC_FAIL: {
            return {
                ...state,
                manualKycLoading: false,
            };
        }
        case EPropMyChallengesTypes.GET_ADDITIONAL_INFO_FROM_MONETARY_TRANSACTIONS_SUCCESS: {
            const takenRetries: Record<string, boolean> = {};
            for (const { retryOptions } of payload as AdditionalInfo[]) {
                const retriedPlanId = retryOptions?.retryCurrentPlanReference;
                if (!retriedPlanId) continue;
                takenRetries[retriedPlanId] = true;
            }
            return {
                ...state,
                additionalInfoFromMonetaryTransactions: payload,
                takenRetries: { ...takenRetries },
            };
        }
        case ESystemTypes.RESET_APP: {
            return {
                ...state,
                challenges: [],
                filteredChallenges: [],
                onlyFundedChallenges: [],
                fundedPropChallenge: [],
                challengeIframe: [],
                selectedChallenge: undefined,
                mainAccountTp: undefined,
                challengeIframePopup: false,
                plans: [],
            };
        }
        default:
            return state;
    }
};

export default PropMyPlansReducer;
