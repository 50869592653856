import axios from 'axios';
import config from '../config/common';
import {
    TestApi,
    AuthApi,
    UserApi,
    CrmPublicApiApi,
    TPApi,
    WebTraderApi,
    TradingAnalyticsApi,
    SumsubApi,
    PaymentApi,
    NotificationsApi,
    KycApi,
    VerifyAccountApi,
    ConfigApi,
    PropApi,
    MteApi,
} from './generatedClientFromSwagger/index';
import store from 'store/index';
import { deleteTokenFromLocalStorage, getTokenFromLocalStorage, saveTokenToLocalStorage } from 'helpers/token';
import { load } from 'recaptcha-v3';
import { ESystemTypes } from 'store/system/actionsTypes';
import { generateUniqueDeviceId } from 'helpers/generateUniqueDeviceId';
import { auth_logout } from 'store/auth/actions';

const baseUrl = config.api.URL;
const checkVer = config.version;

const getGoogleToken = async () => {
    if (config.disableRecaptcha || config.featuresFlags['disableRecaptcha']) return;
    const recaptcha = await load(config.recaptchaKey);
    return await recaptcha.execute('reload');
};

const generateAndSaveDeviceId = () => {
    let deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
        deviceId = generateUniqueDeviceId();
        localStorage.setItem('deviceId', deviceId);
    }
    return deviceId;
};

const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Cache-Control': 'no-cache',
    },
});

axiosInstance.interceptors.request.use(async function (config) {
    const googleToken = await getGoogleToken();
    if (googleToken) {
        config.headers.GoogleAuthorization = googleToken;
    }
    const jwtToken = getTokenFromLocalStorage();
    if (jwtToken) {
        config.headers.Authorization = `Bearer ${jwtToken}`;
    }
    config.headers['Client-Version'] = checkVer;
    config.headers['deviceid'] = generateAndSaveDeviceId();
    return config;
});

let shouldOpenPopUp = true;
const versionCheckCount = Number(localStorage.getItem('versionCheckCount') || 0);

const MAX_VERSION_CHECK_ATTEMPTS = 1;
const VERSION_CHECK_INTERVAL = 30 * 60 * 1000;

const updateVersionTriggerEvent = () => {
    const anyWindow = window as any;
    if (anyWindow.versionUpdateListenerActive && shouldOpenPopUp && versionCheckCount < MAX_VERSION_CHECK_ATTEMPTS) {
        shouldOpenPopUp = false;
        localStorage.setItem('versionCheckCount', (versionCheckCount + 1).toString());
        const newVersionEvent = new CustomEvent('newVersion');
        window.dispatchEvent(newVersionEvent);
    } else if (versionCheckCount >= MAX_VERSION_CHECK_ATTEMPTS) {
        setTimeout(() => {
            localStorage.setItem('versionCheckCount', '0');
        }, VERSION_CHECK_INTERVAL);
    }
};

axiosInstance.interceptors.response.use(
    function (response) {
        const refreshToken = response.headers['refresh-token'];
        if (response.headers['version-update']) {
            updateVersionTriggerEvent();
        }
        if (refreshToken) {
            saveTokenToLocalStorage(refreshToken);
        }
        return response;
    },

    function (error) {
        if (error?.response?.headers['version-update']) {
            updateVersionTriggerEvent();
        }
        if (error?.response?.status === 403 && error?.response?.data?.message === 'blockedCountry') {
            return window.location.replace('/regionNotSupported.html');
        }
        if (429 === error?.response?.status) {
            return window.location.reload();
        }

        if (error?.response?.status === 406 && error?.response?.data === 'unverifiedPhone') {
            // verification
            alert('unverifiedPhone');
            // send verification to account + redirect to verify phone OR {dispatch}
        }

        if (error?.response?.status === 406 && error?.response?.data === 'unverifiedEmail') {
            // verification
            alert('unverifiedEmail');
            // send verification to account + redirect to verify Email OR {dispatch}
        }

        if (401 === error?.response?.status) {
            store.dispatch(auth_logout() as any);
            deleteTokenFromLocalStorage();
            store.dispatch({ type: ESystemTypes.RESET_APP, payload: null });
        } else {
            return Promise.reject(error);
        }
    }
);

const apiInstances = {
    AuthApi: new AuthApi(undefined, baseUrl, axiosInstance),
    CrmPublicApi: new CrmPublicApiApi(undefined, baseUrl, axiosInstance),
    TestApi: new TestApi(undefined, baseUrl, axiosInstance),
    UserApi: new UserApi(undefined, baseUrl, axiosInstance),
    TPApi: new TPApi(undefined, baseUrl, axiosInstance),
    WebTraderApi: new WebTraderApi(undefined, baseUrl, axiosInstance),
    TradingAnalyticsApi: new TradingAnalyticsApi(undefined, baseUrl, axiosInstance),
    PaymentApi: new PaymentApi(undefined, baseUrl, axiosInstance),
    SumsubApi: new SumsubApi(undefined, baseUrl, axiosInstance),
    NotificationsApi: new NotificationsApi(undefined, baseUrl, axiosInstance),
    VerifyApi: new VerifyAccountApi(undefined, baseUrl, axiosInstance),
    KycApi: new KycApi(undefined, baseUrl, axiosInstance),
    ConfigApi: new ConfigApi(undefined, baseUrl, axiosInstance),
    PropApi: new PropApi(undefined, baseUrl, axiosInstance),
    MteApi: new MteApi(undefined, baseUrl, axiosInstance),
    default: axios,
};

export default apiInstances;
