import React from 'react';
import { RetryChallengeBtnProps } from './retryChallengeBtn.scheme';
import useStyles from './retryChallengeBtn.styles';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import Typography from 'components/Typography';
import SpinArrow from 'assets/gif/spin_arrow.gif';
import useRedirectPlanRetryToCheckout from 'hooks/useRedirectPlanRetryToCheckout';
import { useRetryDetails } from 'features/prop/hooks/useEnableRetryLogicMixpanel';
import { mixPanelEventsTypes } from 'helpers/mixPanel';
import analyticsManager from 'helpers/analyticsManager';

const RetryChallengeBtn: React.FunctionComponent<RetryChallengeBtnProps> = () => {
    const classes = useStyles();
    const { t } = useTranslation('prop');
    const { handleSelectedPlanRedirectToCheckout, selectedPlan } = useRedirectPlanRetryToCheckout();
    const mixPanelData = useRetryDetails(selectedPlan?.pPlan?.planId ?? '');

    const handleClick = React.useCallback(() => {
        analyticsManager.trackEvent(mixPanelEventsTypes.PROP_RETRY_TP_WITH_DISCOUNT, mixPanelData);
        handleSelectedPlanRedirectToCheckout();
    }, [handleSelectedPlanRedirectToCheckout, mixPanelData]);

    return (
        <div className={classes.btn_wrapper}>
            <Button onClick={handleClick} className={classes.propRetryBtn} variant="outlined">
                <img src={SpinArrow} alt="SpinArrow" className={classes.spinArrow} />
                <Typography variant="tMdSemibold" className={classes.retry_color}>
                    {t('prop_retry_btn')}
                </Typography>
            </Button>
        </div>
    );
};

export default RetryChallengeBtn;
