import { EPaymentTypes } from './actionsTypes';
import { batch } from 'react-redux';
import axios from 'services/axios';
import { RootState } from '..';
import i18n from 'i18n';
import { DepositResult, DepositTab, DepositTypes } from './interfaces';
import config from 'config/common';
import { mixPanelEventsTypes } from 'helpers/analyticsManager';
import { accounts_getMonetaryTransactionsHistory } from '../accounts/actions';
import {
    CreateMonetaryTransactionResponse,
    CreateWithdrawalDto,
    DepositWireDto,
    DepositWireResponse,
    GetAvailableCurrenciesWithRatesCurrency,
    GetAvailableCurrenciesWithRatesResponse,
    PaymentIframeRequest,
    PaymentIframeResponse,
    PropGoalTypeEnum,
    PropPackage,
    ResultInfoCodeEnum,
    TransferBetweenTPAccountsDto,
    TransferBetweenTPAccountsValidationDto,
} from 'services/generatedClientFromSwagger';
import { AxiosResponse } from 'axios';
import { extractRelevantConditions } from 'helpers/extractConditions';
import { IconsType } from 'components/Icon';
import { getRetryPlanData } from 'features/prop/hooks/useEnableRetryLogicMixpanel';
import analyticsManager from 'helpers/analyticsManager';

export const payment_setDepositResult = (depositResult: DepositResult) => {
    return async (dispatch) => {
        dispatch({ type: EPaymentTypes.DEPOSIT_DONE, payload: depositResult });
        // todo add payment done  /  error
        analyticsManager.trackEvent(mixPanelEventsTypes.DEPOSIT_PROCESS_DONE, depositResult);
    };
};

export const payment_resetDepositErrors = () => {
    return async (dispatch) => {
        dispatch({ type: EPaymentTypes.RESET_DEPOSIT_IFRAME_ERROR });
    };
};

export const payment_resetDepositResult = () => ({
    type: EPaymentTypes.RESET_DEPOSIT_RESULT,
});

export const payment_resetState = () => ({
    type: EPaymentTypes.RESET_STATE,
});

export const payment_setDepositType = (depositType: { tabName: string; type: DepositTypes; subType?: string }) => ({
    type: EPaymentTypes.SET_DEPOSIT_TYPE,
    payload: depositType,
});

export const payment_setDepositAmount = (amount: number | undefined, phone: string | undefined) => ({
    type: EPaymentTypes.SET_DEPOSIT_AMOUNT,
    payload: { amount: amount, phone: phone },
});

export interface Payment_getIframeUrlPayload {
    prop: Payment_PropPayload;
}

export interface Payment_PropPayload {
    challengeId: string;
    goalId: string;
    packageId: string;
    addonsIds?: string[];
    discountCode?: string;
    retryPlanId?: string;
    isRetry?: boolean;
}

export const propCreateFullDiscountPlan = () => {
    return async (dispatch, getState) => {
        const state: RootState = getState();
        const { propPayload, mixPanelPayload } = extractPaymentData(state, true);
        try {
            dispatch({ type: EPaymentTypes.CREATE_FULL_DISCOUNT_PLAN_START });
            const data = (
                await axios.PropApi.propApiControllerCreateFullDiscountPlan({
                    ...propPayload,
                })
            )?.data;
            const depositResultObj = {
                amount: 0,
                currency: '',
                status: data.code === ResultInfoCodeEnum.Success,
                transactionId: '',
                type: DepositTypes['fullDiscountPlan'],
            };
            dispatch(payment_setDepositResult(depositResultObj));
            if (mixPanelPayload) {
                analyticsManager.trackEvent(mixPanelEventsTypes.DEPOSIT_IFRAME_URL, mixPanelPayload);
                analyticsManager.trackEvent(mixPanelEventsTypes.PLAN_PURCHASE_DEPOSIT, mixPanelPayload);
            }
            dispatch({ type: EPaymentTypes.CREATE_FULL_DISCOUNT_PLAN_SUCCESS });
            return true;
        } catch (error) {
            dispatch({ type: EPaymentTypes.CREATE_FULL_DISCOUNT_PLAN_FAILED });
            return false;
        }
    };
};

export const payment_createFreeTrial = () => {
    return async (dispatch, getState) => {
        const state: RootState = getState();
        const { propPayload, mixPanelPayload } = extractPaymentData(state, true);
        try {
            dispatch({ type: EPaymentTypes.CREATE_FREE_TRIAL_PLAN_START });
            const res = (
                await axios.PropApi.propApiControllerFreeTrialPlanPayment({
                    goalId: propPayload.goalId,
                    packageId: propPayload.packageId,
                })
            )?.data;

            if (res.code === ResultInfoCodeEnum.Success) {
                dispatch({
                    type: EPaymentTypes.CREATE_FREE_TRIAL_PLAN_SUCCESS,
                    payload: {
                        ...propPayload,
                        ...res,
                        status: res.code === ResultInfoCodeEnum.Success,
                    },
                });
            } else {
                dispatch({
                    type: EPaymentTypes.CREATE_FREE_TRIAL_PLAN_FAILED,
                    payload: {
                        ...propPayload,
                        ...res,
                        status: false,
                    },
                });
            }
            if (mixPanelPayload) {
                analyticsManager.trackEvent(mixPanelEventsTypes.DEPOSIT_IFRAME_URL, mixPanelPayload);
                analyticsManager.trackEvent(mixPanelEventsTypes.PLAN_PURCHASE_DEPOSIT, mixPanelPayload);
            }
            return true;
        } catch (error) {
            dispatch({
                type: EPaymentTypes.CREATE_FREE_TRIAL_PLAN_FAILED,
                payload: {
                    ...propPayload,
                    status: false,
                },
            });
            return true;
        }
    };
};

export const payment_getIframeUrl = () => {
    return async (dispatch, getState) => {
        try {
            const state: RootState = getState();
            const { propPayload, iframeRequest, mixPanelPayload } = extractPaymentData(state);

            dispatch({ type: EPaymentTypes.GET_DEPOSIT_IFRAME });
            // TODO: need to refactor
            const getIframeRes = await new Promise<AxiosResponse<PaymentIframeResponse>>((resolve, reject) => {
                iframeRequest.tpId = state.prop_myPlans.mainAccountTp?.id as string;
                iframeRequest.redirect_url = config.payment.depositRedirectUrl;

                return axios.PropApi.propApiControllerPropPayment({
                    ...iframeRequest,
                    ...propPayload,
                })
                    .then(resolve)
                    .catch(reject);
            });

            dispatch({
                type: EPaymentTypes.GET_DEPOSIT_IFRAME_SUCCESS,
                payload: getIframeRes.data,
            });

            if (mixPanelPayload) {
                analyticsManager.trackEvent(mixPanelEventsTypes.CONTINUE_TO_PAYMENT, {
                    challengeAmount: state.prop_checkout.selectedPackage?.funding,
                    plan: state.prop_checkout.selectedPackage?.type,
                    auditionFee: state.prop_checkout?.newOrderAmount ?? state.prop_checkout.selectedPackage?.amount,
                    paymentMethod: state.payment.depositType?.type,
                    discountCode: state.prop_checkout?.planPaymentData?.discountCode,
                });
                analyticsManager.trackEvent(mixPanelEventsTypes.DEPOSIT_IFRAME_URL, mixPanelPayload);
                analyticsManager.trackEvent(mixPanelEventsTypes.PLAN_PURCHASE_DEPOSIT, mixPanelPayload);
            }
        } catch (error: any) {
            return batch(() => {
                dispatch({ type: EPaymentTypes.GET_DEPOSIT_IFRAME_FAILED, payload: error?.toString() || '' });
            });
        }
    };
};

export const payment_internalMonetaryTransaction = () => {};

export const payment_withdraw = (request: CreateWithdrawalDto) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EPaymentTypes.WITHDRAW_START });
            await axios.PaymentApi.paymentApiControllerCreateWithdrawal(request);
            dispatch({
                type: EPaymentTypes.WITHDRAW_SUCCESS,
            });

            const mixPanelPayload = {
                tpId: request.tradingPlatformAccountId,
                amount: request.amount,
                type: request.paymentInfo?.$type,
            };

            if (mixPanelPayload) {
                analyticsManager.trackEvent(mixPanelEventsTypes.WITHDRAW_REQUEST, mixPanelPayload);
                analyticsManager.trackEvent(mixPanelEventsTypes.PLAN_PAYOUT_WITHDRAW, mixPanelPayload);
            }
        } catch (error) {
            return batch(() => {
                dispatch({ type: EPaymentTypes.WITHDRAW_FAILED });
            });
        }
    };
};

export const payment_transferBetweenTPAccounts = (request: TransferBetweenTPAccountsDto) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EPaymentTypes.TRANSFER_BETWEEN_ACCOUNTS_START });
            await axios.PaymentApi.paymentApiControllerTransferBetweenTPAccounts(request);
            dispatch({ type: EPaymentTypes.TRANSFER_BETWEEN_ACCOUNTS_SUCCESS });
            dispatch({ type: EPaymentTypes.WITHDRAW_SUCCESS });
            const mixPanelPayload = {
                tpId: request.tradingPlatformAccountId,
                amount: request.amount,
                type: 'InternalPaymentInfo',
            };

            if (mixPanelPayload) {
                analyticsManager.trackEvent(mixPanelEventsTypes.TRANSFER_BETWEEN_ACCOUNTS_REQUEST, mixPanelPayload);
            }
        } catch (error) {
            return batch(() => {
                dispatch({ type: EPaymentTypes.TRANSFER_BETWEEN_ACCOUNTS_FAILED });
            });
        }
    };
};

export const payment_transferBetweenTPAccountsReset = () => ({
    type: EPaymentTypes.TRANSFER_BETWEEN_ACCOUNTS_RESET,
});

export const payment_getRate = (from: string, to: string) => {
    return async (dispatch) => {
        try {
            const res = await axios.PaymentApi.paymentApiControllerGetRate(from, to);
            return Number(res?.data?.rate);
        } catch (error) {
            return batch(() => {
                dispatch({ type: EPaymentTypes.WITHDRAW_FAILED });
            });
        }
    };
};
export const payment_transferBetweenTpAccountsValidation = (request: TransferBetweenTPAccountsValidationDto) => {
    return async (dispatch) => {
        try {
            const res = await axios.PaymentApi.paymentApiControllerTransferBetweenTPAccountsValidation(request);
            return res?.data;
        } catch (error) {
            return batch(() => {
                dispatch({ type: EPaymentTypes.WITHDRAW_FAILED });
            });
        }
    };
};

export const payment_resetWithdrawResult = () => ({
    type: EPaymentTypes.RESET_WITHDRAW_RESULT,
});

export const payment_rejectWithdrawRequest = (monetaryTransactionId: string) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EPaymentTypes.WITHDRAW_CANCEL_START, payload: monetaryTransactionId });
            const res = await axios.PaymentApi.paymentApiControllerCancelWithdrawal({ monetaryTransactionId });
            if (res.data?.status) {
                await dispatch(accounts_getMonetaryTransactionsHistory());
                dispatch({ type: EPaymentTypes.WITHDRAW_CANCEL_SUCCESS, payload: monetaryTransactionId });
                return true;
            }

            dispatch({ type: EPaymentTypes.WITHDRAW_CANCEL_FAILED, payload: monetaryTransactionId });
            return false;
        } catch (error) {
            dispatch({ type: EPaymentTypes.WITHDRAW_CANCEL_FAILED, payload: monetaryTransactionId });
            return false;
        }
    };
};

export const payment_getCryptoWallets = (tpId: string) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EPaymentTypes.GET_CRYPTO_WALLETS_START });
            const res = await axios.PaymentApi.paymentApiControllerCreateCryptoWallet({
                psp: 'coinbase',
                tpId,
            });
            dispatch({ type: EPaymentTypes.GET_CRYPTO_WALLETS_SUCCESS, payload: res.data.addresses });
        } catch (error) {
            dispatch({ type: EPaymentTypes.GET_CRYPTO_WALLETS_FAILED });
        }
    };
};

export const payment_getDepositCryptoOptions = () => {
    return async (dispatch, getState) => {
        try {
            const state: RootState = getState();
            const { propPayload, iframeRequest } = extractPaymentData(state);

            dispatch({ type: EPaymentTypes.GET_DEPOSIT_CRYPTO_OPTIONS });

            const getOptionsRes = await axios.PaymentApi.paymentApiControllerGetAvailableCurrenciesWithRates({
                ...iframeRequest,
                ...propPayload,
                tpId: state.prop_myPlans.mainAccountTp?.id || '', // This should be always available
                redirect_url: config.payment.depositRedirectUrl,
            });

            dispatch({
                type: EPaymentTypes.GET_DEPOSIT_CRYPTO_OPTIONS_SUCCESS,
                payload: getOptionsRes.data.currencies,
            });
        } catch (error: any) {
            return batch(() => {
                dispatch({ type: EPaymentTypes.GET_DEPOSIT_CRYPTO_OPTIONS_FAILED, payload: error?.toString() || '' });
            });
        }
    };
};

export const payment_setDepositCryptoOption = (option: GetAvailableCurrenciesWithRatesCurrency) => ({
    type: EPaymentTypes.SET_DEPOSIT_CRYPTO_OPTION,
    payload: option,
});

export const payment_getPaymentTypes = (tpId: string) => {
    return async (dispatch) => {
        try {
            const depositTabsMetaData: { [type: string]: DepositTab } = {
                [DepositTypes.payotop]: {
                    type: DepositTypes.payotop,
                    tabT: 'myWallet:deposit.credit_card.tab_name',
                    titleT: 'myWallet:deposit.credit_card.title',
                    subTitle: '',
                    btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
                    tKey: 'credit_card',
                    icon: IconsType.credit_card_piqk,
                    isIframe: true,
                    isActive: false,
                    order: 0,
                },
                [DepositTypes.safecharge]: {
                    type: DepositTypes.safecharge,
                    tabT: 'myWallet:deposit.safe_charge.tab_name',
                    titleT: 'myWallet:deposit.safe_charge.title',
                    subTitle: '',
                    btnT: 'myWallet:deposit.safe_charge.make_deposit_btn',
                    tKey: 'safe_charge',
                    icon: IconsType.electronic_pay,
                    isIframe: true,
                    isActive: false,
                    order: 1,
                },
                [DepositTypes.praxis]: {
                    type: DepositTypes.praxis,
                    tabT: 'myWallet:deposit.praxis.tab_name',
                    titleT: 'myWallet:deposit.praxis.title',
                    subTitle: '',
                    btnT: 'myWallet:deposit.praxis.make_deposit_btn',
                    tKey: 'praxis',
                    icon: IconsType.credit_card_piqk,
                    isIframe: true,
                    isActive: false,
                    order: 2,
                },
                [DepositTypes.bridgerpay]: {
                    type: DepositTypes.bridgerpay,
                    tabT: 'myWallet:deposit.bridgerpay.tab_name',
                    titleT: 'myWallet:deposit.bridgerpay.title',
                    subTitle: '',
                    btnT: 'myWallet:deposit.bridgerpay.make_deposit_btn',
                    tKey: 'bridgerpay',
                    icon: IconsType.credit_card_piqk,
                    isIframe: true,
                    isActive: false,
                    order: 3,
                },
                [DepositTypes['bank-transfer']]: {
                    type: DepositTypes['bank-transfer'],
                    tabT: 'myWallet:deposit.bank_transfer.tab_name',
                    titleT: '',
                    subTitle: '',
                    btnT: 'myWallet:deposit.bank_transfer.make_deposit_btn',
                    tKey: 'bank_transfer',
                    icon: IconsType.bank_wire_piqk,
                    isIframe: false,
                    isActive: false,
                    order: 4,
                },
                [DepositTypes.links]: {
                    type: DepositTypes.links,
                    tabT: 'myWallet:deposit.link.tab_name',
                    titleT: 'myWallet:deposit.link.title',
                    subTitle: '',
                    btnT: 'myWallet:deposit.link.make_deposit_btn',
                    tKey: 'link',
                    icon: IconsType.deposit_links,
                    isIframe: false,
                    links: config.depositLinks,
                    isActive: false,
                    order: 5,
                },
                [DepositTypes.crypto]: {
                    type: DepositTypes.crypto,
                    tabT: 'myWallet:deposit.crypto.tab_name',
                    titleT: 'myWallet:deposit.crypto.title',
                    btnT: 'myWallet:deposit.crypto.make_deposit_btn',
                    subTitle: config?.cryptoSubtitle ? config.cryptoSubtitle : 'myWallet:deposit.crypto.sub_title',
                    tKey: 'crypto',
                    icon: IconsType.bitcoin_crypto_piqk,
                    isIframe: false,
                    links: config.depositCrypto,
                    isActive: false,
                    order: 6,
                },
                [DepositTypes.realCrypto]: {
                    type: DepositTypes.realCrypto,
                    tabT: 'myWallet:deposit.crypto.tab_name',
                    titleT: 'myWallet:deposit.crypto.title',
                    subTitle: 'myWallet:deposit.crypto.sub_title',
                    btnT: 'myWallet:deposit.crypto.make_deposit_btn',
                    tKey: 'crypto',
                    icon: IconsType.bitcoin_crypto_piqk,
                    isIframe: false,
                    isActive: false,
                    order: 7,
                },
                [DepositTypes.outsource]: {
                    type: DepositTypes.outsource,
                    tabT: 'myWallet:deposit.outsource.tab_name',
                    titleT: 'myWallet:deposit.outsource.title',
                    subTitle: '',
                    tKey: 'outsource',
                    icon: IconsType.ellipse,
                    isIframe: false,
                    iframeUrl:
                        config.depositOutsource.url && !config.depositOutsource.blank
                            ? config.depositOutsource.url
                            : undefined,
                    link:
                        config.depositOutsource.url && config.depositOutsource.blank
                            ? config.depositOutsource.url
                            : undefined,
                    linkTargetBlank: !!config.depositOutsource.blank,
                    outsideLink: true,
                    isActive: false,
                    order: 99,
                    hidden: !Boolean(config?.depositOutsource.url),
                },
                [DepositTypes.payretailers]: {
                    type: DepositTypes.payretailers,
                    tabT: 'myWallet:deposit.payretailers.tab_name',
                    titleT: 'myWallet:deposit.payretailers.title',
                    subTitle: '',
                    btnT: 'myWallet:deposit.payretailers.make_deposit_btn',
                    tKey: 'payretailers',
                    icon: IconsType.credit_card_piqk,
                    isIframe: true,
                    iframeNewTab: true,
                    isActive: false,
                    order: 9,
                },
                [DepositTypes.bpwallet]: {
                    type: DepositTypes.bpwallet,
                    tabT: 'myWallet:deposit.bpwallet.tab_name',
                    titleT: 'myWallet:deposit.bpwallet.title',
                    subTitle: '',
                    btnT: 'myWallet:deposit.bpwallet.make_deposit_btn',
                    tKey: 'bpwallet',
                    icon: IconsType.credit_card_piqk,
                    isIframe: true,
                    iframeNewTab: true,
                    isActive: false,
                    order: 10,
                },
                [DepositTypes.gr8pay]: {
                    type: DepositTypes.gr8pay,
                    tabT: 'myWallet:deposit.gr8pay.tab_name',
                    titleT: 'myWallet:deposit.gr8pay.title',
                    subTitle: '',

                    btnT: 'myWallet:deposit.gr8pay.make_deposit_btn',
                    tKey: 'gr8pay',
                    icon: IconsType.credit_card_piqk,
                    isIframe: true,
                    isActive: false,
                    order: 11,
                },
                [DepositTypes.neobanq]: {
                    type: DepositTypes.neobanq,
                    tabT: 'myWallet:deposit.neobanq.tab_name',
                    titleT: 'myWallet:deposit.neobanq.title',
                    subTitle: '',

                    btnT: 'myWallet:deposit.neobanq.make_deposit_btn',
                    tKey: 'neobanq',
                    icon: IconsType.credit_card_piqk,
                    isIframe: true,
                    isActive: false,
                    order: 12,
                },
                [DepositTypes.maldopay]: {
                    type: DepositTypes.maldopay,
                    tabT: 'myWallet:deposit.maldopay.tab_name',
                    titleT: 'myWallet:deposit.maldopay.title',
                    subTitle: '',

                    btnT: 'myWallet:deposit.maldopay.make_deposit_btn',
                    tKey: 'maldopay',
                    icon: IconsType.credit_card_piqk,
                    isIframe: true,
                    isActive: false,
                    order: 13,
                },
                [DepositTypes.pagsmile]: {
                    type: DepositTypes.pagsmile,
                    tabT: 'myWallet:deposit.pagsmile.tab_name',
                    titleT: 'myWallet:deposit.pagsmile.title',
                    subTitle: '',
                    btnT: 'myWallet:deposit.pagsmile.make_deposit_btn',
                    tKey: 'pagsmile',
                    icon: IconsType.electronic_pay,
                    isIframe: true,
                    isActive: false,
                    order: 14,
                },
                [DepositTypes.paymentechnologies]: {
                    type: DepositTypes.paymentechnologies,
                    tabT: 'myWallet:deposit.paymentechnologies.tab_name',
                    titleT: 'myWallet:deposit.paymentechnologies.title',
                    subTitle: '',
                    btnT: 'myWallet:deposit.paymentechnologies.make_deposit_btn',
                    tKey: 'paymentechnologies',
                    icon: IconsType.credit_card_piqk,
                    isIframe: true,
                    iframeNewTab: true,
                    isActive: false,
                    order: 15,
                },
                [DepositTypes.ngpayment]: {
                    type: DepositTypes.ngpayment,
                    tabT: 'myWallet:deposit.ngpayment.tab_name',
                    titleT: 'myWallet:deposit.ngpayment.title',
                    subTitle: '',

                    btnT: 'myWallet:deposit.ngpayment.make_deposit_btn',
                    tKey: 'ngpayment',
                    icon: IconsType.credit_card_piqk,
                    isIframe: true,
                    iframeNewTab: true,
                    isActive: false,
                    order: 16,
                },
                [DepositTypes.knoxsecure]: {
                    type: DepositTypes.knoxsecure,
                    tabT: 'myWallet:deposit.knoxsecure.tab_name',
                    titleT: 'myWallet:deposit.knoxsecure.title',
                    subTitle: '',

                    btnT: 'myWallet:deposit.knoxsecure.make_deposit_btn',
                    tKey: 'knoxsecure',
                    icon: IconsType.credit_card_piqk,
                    isIframe: true,
                    isActive: false,
                    order: 17,
                },
                [DepositTypes.quikipay]: {
                    type: DepositTypes.quikipay,
                    tabT: 'myWallet:deposit.quikipay.tab_name',
                    titleT: 'myWallet:deposit.quikipay.title',
                    subTitle: '',
                    btnT: 'myWallet:deposit.quikipay.make_deposit_btn',
                    tKey: 'quikipay',
                    icon: IconsType.credit_card_piqk,
                    isIframe: true,
                    isActive: false,
                    order: 18,
                },
                [DepositTypes.rafinita]: {
                    type: DepositTypes.rafinita,
                    tabT: 'myWallet:deposit.rafinita.tab_name',
                    titleT: 'myWallet:deposit.rafinita.title',
                    subTitle: '',

                    btnT: 'myWallet:deposit.rafinita.make_deposit_btn',
                    tKey: 'rafinita',
                    icon: IconsType.credit_card_piqk,
                    isIframe: true,
                    isActive: false,
                    order: 19,
                },
                [DepositTypes.chargemoney]: {
                    type: DepositTypes.chargemoney,
                    tabT: 'myWallet:deposit.chargemoney.tab_name',
                    titleT: 'myWallet:deposit.chargemoney.title',
                    subTitle: '',
                    btnT: 'myWallet:deposit.chargemoney.make_deposit_btn',
                    tKey: 'chargemoney',
                    icon: IconsType.credit_card_piqk,
                    isIframe: true,
                    isActive: false,
                    order: 20,
                },
                [DepositTypes.gcg]: {
                    type: DepositTypes.gcg,
                    tabT: 'myWallet:deposit.gcg.tab_name',
                    titleT: 'myWallet:deposit.gcg.title',
                    subTitle: '',
                    btnT: 'myWallet:deposit.gcg.make_deposit_btn',
                    tKey: 'gcg',
                    icon: IconsType.credit_card_piqk,
                    isIframe: true,
                    isActive: false,
                    order: 21,
                },
                [DepositTypes.neonpay]: {
                    type: DepositTypes.neonpay,
                    tabT: 'myWallet:deposit.neonpay.tab_name',
                    titleT: 'myWallet:deposit.neonpay.title',
                    btnT: 'myWallet:deposit.neonpay.make_deposit_btn',
                    tKey: 'neonpay',
                    icon: IconsType.credit_card,
                    isIframe: true,
                    isActive: false,
                    order: 22,
                },
                [DepositTypes.dikeshield]: {
                    type: DepositTypes.dikeshield,
                    tabT: 'myWallet:deposit.credit_card.tab_name',
                    titleT: 'myWallet:deposit.credit_card.title',
                    btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
                    tKey: 'dikeshield',
                    icon: IconsType.credit_card,
                    isIframe: true,
                    isActive: false,
                    order: 23,
                },
                [DepositTypes.nonstopay]: {
                    type: DepositTypes.nonstopay,
                    tabT: 'myWallet:deposit.credit_card.tab_name',
                    titleT: 'myWallet:deposit.credit_card.title',
                    btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
                    tKey: 'nonstopay',
                    icon: IconsType.credit_card,
                    isIframe: true,
                    isActive: false,
                    order: 24,
                },
                [DepositTypes.korapay]: {
                    type: DepositTypes.korapay,
                    tabT: 'myWallet:deposit.credit_card.tab_name',
                    titleT: 'myWallet:deposit.credit_card.title',
                    btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
                    tKey: 'korapay',
                    icon: IconsType.credit_card,
                    isIframe: true,
                    isActive: false,
                    order: 25,
                },
                [DepositTypes.kasha]: {
                    type: DepositTypes.kasha,
                    tabT: 'myWallet:deposit.credit_card.tab_name',
                    titleT: 'myWallet:deposit.credit_card.title',
                    btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
                    tKey: 'kasha',
                    icon: IconsType.credit_card,
                    isIframe: true,
                    isActive: false,
                    order: 26,
                },
                [DepositTypes.payport]: {
                    type: DepositTypes.payport,
                    tabT: 'myWallet:deposit.credit_card.tab_name',
                    titleT: 'myWallet:deposit.credit_card.title',
                    btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
                    tKey: 'payport',
                    icon: IconsType.credit_card,
                    isIframe: true,
                    isActive: false,
                    order: 26,
                },
                [DepositTypes.pelecard]: {
                    type: DepositTypes.pelecard,
                    tabT: 'myWallet:deposit.credit_card.tab_name',
                    titleT: 'myWallet:deposit.credit_card.title',
                    btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
                    tKey: 'pelecard',
                    icon: IconsType.credit_card,
                    isIframe: true,
                    isActive: false,
                    order: 27,
                },
                [DepositTypes.bitolo]: {
                    type: DepositTypes.bitolo,
                    tabT: 'myWallet:deposit.credit_card.tab_name',
                    titleT: 'myWallet:deposit.credit_card.title',
                    btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
                    tKey: 'bitolo',
                    icon: IconsType.credit_card,
                    isIframe: true,
                    isActive: false,
                    order: 29,
                },
                [DepositTypes.payneteasy]: {
                    type: DepositTypes.payneteasy,
                    tabT: 'myWallet:deposit.credit_card.tab_name',
                    titleT: 'myWallet:deposit.credit_card.title',
                    btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
                    tKey: 'payneteasy',
                    icon: IconsType.credit_card,
                    isIframe: true,
                    isActive: false,
                    order: 30,
                },
                [DepositTypes.vepara]: {
                    type: DepositTypes.vepara,
                    tabT: 'myWallet:deposit.credit_card.tab_name',
                    titleT: 'myWallet:deposit.credit_card.title',
                    btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
                    tKey: 'vepara',
                    icon: IconsType.credit_card,
                    isIframe: true,
                    isActive: false,
                    order: 31,
                },
                [DepositTypes.nowpayments]: {
                    type: DepositTypes.nowpayments,
                    tabT: 'myWallet:deposit.credit_card.tab_name',
                    titleT: 'myWallet:deposit.credit_card.title',
                    btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
                    tKey: 'nowpayments',
                    icon: IconsType.credit_card,
                    isIframe: true,
                    isActive: false,
                    order: 32,
                },
                [DepositTypes.match2pay]: {
                    type: DepositTypes.match2pay,
                    tabT: 'myWallet:deposit.credit_card.tab_name',
                    titleT: 'myWallet:deposit.credit_card.title',
                    btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
                    tKey: 'match2pay',
                    icon: IconsType.credit_card,
                    isIframe: true,
                    isActive: false,
                    order: 34,
                },
                [DepositTypes.paytechno]: {
                    type: DepositTypes.paytechno,
                    tabT: 'myWallet:deposit.credit_card.tab_name',
                    titleT: 'myWallet:deposit.credit_card.title',
                    btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
                    tKey: 'paytechno',
                    icon: IconsType.credit_card,
                    isIframe: true,
                    isActive: false,
                    order: 35,
                },
                [DepositTypes.paragon]: {
                    type: DepositTypes.paragon,
                    tabT: 'myWallet:deposit.credit_card.tab_name',
                    titleT: 'myWallet:deposit.credit_card.title',
                    btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
                    tKey: 'paragon',
                    icon: IconsType.credit_card,
                    isIframe: true,
                    isActive: false,
                    order: 36,
                },
                [DepositTypes.epsilon]: {
                    type: DepositTypes.epsilon,
                    tabT: 'myWallet:deposit.credit_card.tab_name',
                    titleT: 'myWallet:deposit.credit_card.title',
                    btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
                    tKey: 'epsilon',
                    icon: IconsType.credit_card,
                    isIframe: true,
                    isActive: false,
                    order: 37,
                },
                [DepositTypes.payku]: {
                    type: DepositTypes.payku,
                    tabT: 'myWallet:deposit.credit_card.tab_name',
                    titleT: 'myWallet:deposit.credit_card.title',
                    btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
                    tKey: 'payku',
                    icon: IconsType.credit_card,
                    isIframe: true,
                    isActive: false,
                    order: 38,
                },
                [DepositTypes.toptechpay]: {
                    type: DepositTypes.toptechpay,
                    tabT: 'myWallet:deposit.credit_card.tab_name',
                    titleT: 'myWallet:deposit.credit_card.title',
                    btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
                    tKey: 'toptechpay',
                    icon: IconsType.credit_card,
                    isIframe: true,
                    isActive: false,
                    order: 39,
                },
                [DepositTypes.cricpayz]: {
                    type: DepositTypes.cricpayz,
                    tabT: 'myWallet:deposit.credit_card.tab_name',
                    titleT: 'myWallet:deposit.credit_card.title',
                    btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
                    tKey: 'cricpayz',
                    icon: IconsType.credit_card,
                    isIframe: true,
                    isActive: false,
                    order: 39,
                },
                [DepositTypes.payok]: {
                    type: DepositTypes.payok,
                    tabT: 'myWallet:deposit.credit_card.tab_name',
                    titleT: 'myWallet:deposit.credit_card.title',
                    btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
                    tKey: 'payok',
                    icon: IconsType.credit_card,
                    isIframe: true,
                    isActive: false,
                    order: 40,
                },
                [DepositTypes.epay]: {
                    type: DepositTypes.epay,
                    tabT: 'myWallet:deposit.credit_card.tab_name',
                    titleT: 'myWallet:deposit.credit_card.title',
                    btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
                    tKey: 'epay',
                    icon: IconsType.credit_card,
                    isIframe: true,
                    isActive: false,
                    order: 41,
                },
                [DepositTypes.coinPayments]: {
                    type: DepositTypes.coinPayments,
                    tabT: 'myWallet:deposit.coinPayments.tab_name',
                    titleT: 'myWallet:deposit.coinPayments.title',
                    btnT: 'myWallet:deposit.coinPayments.make_deposit_btn',
                    tKey: 'coinPayments',
                    icon: IconsType.qRCode,
                    isIframe: false,
                    isActive: false,
                    isApiCrypto: true,
                    isApiCryptoSymbolSelection: true,
                    order: 42,
                },
            };
            dispatch({ type: EPaymentTypes.GET_PAYMENT_TYPES_START });
            const res = await axios.PaymentApi.paymentApiControllerGetPaymentTypes({ tpId });
            const firstItem = res.data[0];
            dispatch(
                payment_setDepositType({
                    tabName: `${firstItem.name}_*_${firstItem.type || ''}`,
                    type: firstItem.name as any,
                    subType: firstItem.type,
                })
            );
            const mappedResults: { [type: string]: DepositTab } = res.data.reduce<{ [type: string]: DepositTab }>(
                (acc, type) => ({
                    ...acc,
                    [`${type.name}_*_${type.type || ''}`]: {
                        ...depositTabsMetaData[type.name],
                        overrideName: type.overrideName,
                        subType: type.type,
                        iframeNewTab: type.openNewTab,
                        openAtTheSameWindow: type['openAtTheSameWindow'],
                    },
                }),
                {}
            );
            dispatch({ type: EPaymentTypes.GET_PAYMENT_TYPES_SUCCESS, payload: mappedResults });
        } catch (error) {
            dispatch({ type: EPaymentTypes.GET_PAYMENT_TYPES_FAILED });
        }
    };
};

export interface Payment_getReferenceNumberPayload {
    depositWireDto: DepositWireDto;
    prop?: Payment_PropPayload;
}

export const payment_getReferenceNumber = (payload: Payment_getReferenceNumberPayload) => {
    return async (dispatch, getState) => {
        try {
            const state: RootState = getState();
            const { propPayload, mixPanelPayload } = extractPaymentData(state);
            dispatch({ type: EPaymentTypes.GET_REFERENCE_NUMBER_START });

            let res: AxiosResponse<DepositWireResponse> = await axios.PropApi.propApiControllerPropDepositWire({
                ...payload.depositWireDto,
                ...propPayload,
                tpId: state.prop_myPlans.mainAccountTp?.id as string,
            });
            const depositResultObj = {
                amount: payload.depositWireDto.amount,
                currency: '',
                status: !!res.data.referenceId,
                transactionId: '',
                type: DepositTypes['bank-transfer'],
            };
            dispatch(payment_setDepositResult(depositResultObj));
            dispatch({ type: EPaymentTypes.GET_REFERENCE_NUMBER_SUCCESS, payload: res.data.referenceId });
            if (mixPanelPayload) {
                analyticsManager.trackEvent(mixPanelEventsTypes.PLAN_PURCHASE_DEPOSIT, mixPanelPayload);
            }
            return true;
        } catch (error) {
            const depositResultObj = {
                amount: payload.depositWireDto.amount,
                currency: '',
                status: false,
                transactionId: '',
                type: DepositTypes['bank-transfer'],
            };
            dispatch(payment_setDepositResult(depositResultObj));
            dispatch({ type: EPaymentTypes.GET_REFERENCE_NUMBER_FAILED });
            return false;
        }
    };
};

export const challengeUpdated = () => {
    return async (dispatch) => {
        dispatch({ type: EPaymentTypes.CHALLENGE_UPDATED });
    };
};

export const resetChallengeUpdate = () => {
    return async (dispatch) => {
        dispatch({ type: EPaymentTypes.RESET_CHALLENGE_UPDATED });
    };
};

export const extractPaymentData = (state: RootState, freePurchase = false) => {
    const planObject = state.prop_newChallenge.newGoals[state.prop_checkout.planPaymentData.goalIndex!];

    const isRetry = !!state.prop_checkout.planPaymentData.retryPlanId;

    const propPayload: Payment_PropPayload = {
        challengeId: state.prop_newChallenge?.challengePaymentData?.challengeId || '',
        goalId: state.prop_checkout?.planPaymentData?.goalId || '',
        packageId: state.prop_checkout?.planPaymentData?.packageId || '',
        addonsIds: state.prop_checkout?.selectedAddonsIds || [],
        discountCode: state.prop_checkout?.planPaymentData?.discountCode || '',
        isRetry: !!state.prop_checkout?.planPaymentData?.retryPlanId,
        retryPlanId: state.prop_checkout?.planPaymentData?.retryPlanId,
    };

    const { language } = i18n;
    const { depositAmount, depositType, depositPhone } = state.payment;
    const { selectedTPAccount } = state.user;
    if (
        !freePurchase &&
        (((!depositType || !DepositTypes[depositType.type]) && planObject.type !== PropGoalTypeEnum.Free) ||
            !selectedTPAccount)
    ) {
        throw new Error('Invalid deposit type, amount, or account');
    }
    const iframeRequest: PaymentIframeRequest = {
        tpId: selectedTPAccount?.id || '',
        amount: depositAmount ?? 0,
        psp: depositType?.type || '',
        paymentType: depositType?.subType ?? '',
        language,
        redirect_url: config.payment.depositRedirectUrl,
        phone: depositPhone,
        symbolId: state.payment.selectedCrptoOption?.id,
    };
    if (!isRetry) {
        const conditions = isRetry
            ? {}
            : extractRelevantConditions(
                  planObject?.challenges?.[0],
                  state.prop_checkout?.planPaymentData?.packageIndex || 0
              );

        const mixPanelPayload = {
            ...iframeRequest,
            tpName: selectedTPAccount?.name,
            currency: selectedTPAccount?.baseCurrency?.code,
            conditions: conditions ?? {},
        };
        return { planObject, propPayload, iframeRequest, mixPanelPayload };
    } else {
        if (state.prop_checkout.planPaymentData.retryPlanId && state?.prop_myPlans?.plans.length) {
            const retryPlan = state?.prop_myPlans?.plans?.find?.(
                (plan) => plan.pPlan?.planId === state.prop_checkout.planPaymentData.retryPlanId
            );
            if (retryPlan) {
                const mixPanelRetry = getRetryPlanData(retryPlan);
                analyticsManager.trackEvent(mixPanelEventsTypes.PROP_RETRY_PAYMENT_SUCCESSFUL, mixPanelRetry);
            }
        }
    }

    return { planObject, propPayload, iframeRequest, mixPanelPayload: undefined };
};
export const payment_createPendingDeposit = () => {
    return async (dispatch, getState) => {
        try {
            const state: RootState = getState();
            const propState = state.prop_myPlans;

            const { propPayload, mixPanelPayload } = extractPaymentData(state);

            dispatch({ type: EPaymentTypes.CREATE_PENDING_PAYMENT_START });

            await new Promise<AxiosResponse<CreateMonetaryTransactionResponse>>((resolve, reject) => {
                return axios.PropApi.propApiControllerPropPendingPayment({
                    tpId: propState.mainAccountTp?.id as string,
                    amount: 0,
                    ...propPayload,
                })
                    .then(resolve)
                    .catch(reject);
            });

            dispatch({
                type: EPaymentTypes.CREATE_PENDING_PAYMENT_SUCCESS,
                payload: 'success',
            });
            if (mixPanelPayload) {
                analyticsManager.trackEvent(mixPanelEventsTypes.PLAN_OUTSOURCE_DEPOSIT, mixPanelPayload);
            }
        } catch (error: any) {
            return batch(() => {
                dispatch({ type: EPaymentTypes.CREATE_PENDING_PAYMENT_FAILED, payload: error?.toString() || '' });
            });
        }
    };
};
