import { EAccountsTypes } from './actionsTypes';
import { batch } from 'react-redux';
import axios from 'services/axios';
import { RootState } from '..';
import { mixPanelEventsTypes } from 'helpers/mixPanel';
import { user_UpdateUserInfo } from 'store/user/actions';
import analyticsManager from 'helpers/analyticsManager';

export const accounts_getAccountsBalance = (accountsNames: string[]) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EAccountsTypes.GET_ACCOUNTS_DATA });
            const accountsData = await axios.TPApi.tPControllerGetTPData({ accounts: accountsNames });
            dispatch({ type: EAccountsTypes.GET_ACCOUNTS_DATA_SUCCESS, payload: accountsData.data.accounts });
            Object.keys(accountsData.data.accounts).forEach((tpName) => {
                analyticsManager.trackEvent(mixPanelEventsTypes.TP_ACCOUNT_BALANCE, {
                    tpName,
                    balance: accountsData.data.accounts[tpName].balance,
                });
            });
        } catch (error) {
            return batch(() => {
                dispatch({ type: EAccountsTypes.GET_ACCOUNTS_DATA_FAILED });
            });
        }
    };
};

export const accounts_getMonetaryTransactionsHistory = () => {
    // use this method to get withdrawals.
    return async (dispatch) => {
        try {
            dispatch({ type: EAccountsTypes.GET_MONETARY_TRANSACTIONS_DATA });
            const monetaryTransactionsData = await axios.TPApi.tPControllerGetMonetaryTransactionHistory();
            dispatch({
                type: EAccountsTypes.GET_MONETARY_TRANSACTIONS_DATA_SUCCESS,
                payload: monetaryTransactionsData.data.transactions,
            });
        } catch (error) {
            return batch(() => {
                dispatch({ type: EAccountsTypes.GET_MONETARY_TRANSACTIONS_DATA_FAILED });
            });
        }
    };
};

export const accounts_getTpTradingHistory = (tpId: string) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EAccountsTypes.GET_TP_TRADING_HISTORY });
            const tradingHistory = await axios.TPApi.tPControllerGetTradingHistory({ tradingPlatformAccountId: tpId });
            dispatch({
                type: EAccountsTypes.GET_TP_TRADING_HISTORY_SUCCESS,
                payload: { [tpId]: tradingHistory.data.tradingHistory },
            });
        } catch (error) {
            return batch(() => {
                dispatch({ type: EAccountsTypes.GET_TP_TRADING_HISTORY_FAILED });
            });
        }
    };
};

export const accounts_changeTpPassword = (oldPassword: string, newPassword: string) => {
    return async (dispatch, getState) => {
        try {
            const state: RootState = getState();
            const userState = state.prop_myPlans;
            const accoutName = userState.mainAccountTp?.name;

            if (accoutName) {
                dispatch({ type: EAccountsTypes.CHANGE_TP_PASSWORD_START });
                const changeTpPasswordRes = await axios.TPApi.tPControllerChangeTpPassword({
                    accoutName,
                    oldPassword,
                    newPassword,
                });

                if (!changeTpPasswordRes.data.status) {
                    throw new Error('');
                }
            }
            dispatch({ type: EAccountsTypes.CHANGE_TP_PASSWORD_SUCCESS });
        } catch (error) {
            return batch(() => {
                dispatch({ type: EAccountsTypes.CHANGE_TP_PASSWORD_FAILED });
            });
        }
    };
};

export const accounts_resetChangeTpPasswordStatus = () => ({
    type: EAccountsTypes.RESET_CHANGE_TP_PASSWORD_STATUS,
});

/* Email notifications */

export const accounts_getEmailNotificationsSettings = () => {
    return async (dispatch) => {
        try {
            const userNotificationsSettings = await axios.UserApi.userControllerGetNotificationSettings();

            if (!userNotificationsSettings) {
                throw new Error('');
            }

            delete userNotificationsSettings.data.result;

            dispatch({
                type: EAccountsTypes.GET_EMAIL_NOTIFICATIONS_SETTINGS_DATA_SUCCESS,
                payload: userNotificationsSettings.data,
            });
        } catch (e) {}
    };
};

export const accounts_updateEmailNotificationsSettings = (payload: any) => {
    return async (dispatch) => {
        dispatch({ type: EAccountsTypes.UPDATE_EMAIL_NOTIFICATIONS_SETTINGS_START });

        const updateNotificationsEmailSettingsRes = axios.UserApi.userControllerUpdateNotificationSettings(payload);
        const updateUserInfoRes = dispatch(
            user_UpdateUserInfo({ custom_field_language: payload.custom_field_language })
        );

        Promise.all([updateNotificationsEmailSettingsRes, updateUserInfoRes])
            .then((values) => {
                dispatch({ type: EAccountsTypes.UPDATE_EMAIL_NOTIFICATIONS_SETTINGS_SUCCESS, payload });
            })
            .catch((error) => {
                dispatch({ type: EAccountsTypes.UPDATE_EMAIL_NOTIFICATIONS_FAILED });
            });
    };
};
