import { Grid, InputLabel } from '@material-ui/core';
import Select from 'components/Inputs/Select';
import TextField from 'components/Inputs/TextField';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleLocationAutocompletePayload } from '../google-location-autocomplete.scheme';
import { FallBackFieldsProps } from './fallback-fields.scheme';
import useStyles from './fallback-fields.styles';
import { IconsType } from 'components/Icon';

const inputs = [
    {
        id: 'city',
        placeholderT: 'prop:customer_details_form.city.placeholder',
        label: 'prop:customer_details_form.city.label',
        required: true,
        errorTextT: 'common:validations.fields.city',
    },
    {
        id: 'street',
        placeholderT: 'prop:customer_details_form.street.placeholder',
        label: 'prop:customer_details_form.street.label',
        required: true,
        errorTextT: 'common:validations.fields.street',
    },
    {
        id: 'postalCode',
        placeholderT: 'prop:customer_details_form.postalCode.placeholder',
        label: 'prop:customer_details_form.postalCode.label',
        required: false,
        errorTextT: 'common:validations.fields.postalCode',
    },
];

const FallBackFields: React.FunctionComponent<FallBackFieldsProps> = (props) => {
    const { defaultValueObject, error, countryOptions, onChange } = props;
    const classes = useStyles();
    const { t } = useTranslation('countries');
    const [tempObject, setTempObject] = React.useState<GoogleLocationAutocompletePayload>({});

    useEffect(() => {
        setTempObject(() => {
            let street = defaultValueObject?.address || '';
            if (defaultValueObject?.addressNumber) {
                street = `${street} ${defaultValueObject.addressNumber}`;
            }
            return {
                country: defaultValueObject?.country || '',
                country_short:
                    defaultValueObject?.country_short ||
                    countryOptions.find(
                        (item) => item.label.toLowerCase() === (defaultValueObject?.country || '').toLowerCase()
                    )?.shortName ||
                    '',
                city: defaultValueObject?.city || '',
                street: street,
                postalCode: defaultValueObject?.postalCode || '',
            };
        });
    }, []);

    const handleOnFieldChange = useCallback(
        (field: string, value: string) => {
            const trimmedValue = value.trimStart();
            const newTempObject = {
                ...tempObject,
                [field]: trimmedValue,
                address: '',
            };
            if (field === 'country_short') {
                newTempObject.country = countryOptions.find((item) => item.shortName === trimmedValue)?.label || '';
            }
            newTempObject.address = newTempObject.street || '';
            setTempObject(newTempObject);
            onChange && onChange(newTempObject);
        },
        [countryOptions, onChange, tempObject]
    );

    const postalCodeValid = React.useMemo(() => {
        if (tempObject.postalCode) return tempObject.postalCode.length < 21 && tempObject.postalCode.length > 2;
        return false;
    }, [tempObject.postalCode]);

    const cityValid = React.useMemo(() => {
        if (tempObject.city) return !(tempObject.city.length < 81);
        return false;
    }, [tempObject.city]);

    const streetValid = React.useMemo(() => {
        if (tempObject.street) return !(tempObject.street.length < 251);
        return false;
    }, [tempObject.street]);

    const countriesOptions = useMemo(
        () =>
            countryOptions.map((item) => ({
                label: t(`countries.${item.label}`, { defaultValue: item.label }),
                value: item.shortName,
            })),
        [countryOptions, t]
    );

    const handleSelectChange: ((value: any) => void) | undefined = useCallback(
        (value) => handleOnFieldChange('country_short', value),
        [handleOnFieldChange]
    );

    return (
        <div className={classes.container}>
            <div className={classes.select}>
                <Select
                    disabled={props.disabled}
                    fullWidth={true}
                    key={`google-autocomplete-country-${countryOptions.length}`}
                    id={'country'}
                    placeholder={t('prop:customer_details_form.country.placeholder')}
                    label={t('prop:customer_details_form.country.label')}
                    onChange={handleSelectChange}
                    value={tempObject.country_short}
                    error={error && !tempObject.country_short}
                    options={countriesOptions}
                    helperText={
                        (error &&
                            !tempObject.country_short &&
                            t('common:validations.mixed.required', {
                                path: t('common:validations.fields.country'),
                            })) ||
                        undefined
                    }
                />
            </div>

            {inputs.map((input) => {
                const errorsObj =
                    (input.required && error && !tempObject[input.id]) ||
                    (input.id === 'postalCode' && error && !postalCodeValid) ||
                    (input.required && input.id === 'city' && error && cityValid) ||
                    (input.required && input.id === 'street' && error && streetValid);
                return (
                    <div key={`fallback_${input.id}`}>
                        <TextField
                            label={t(input?.label)}
                            disabled={props.disabled}
                            fullWidth={true}
                            id={input.id}
                            name={input.id}
                            placeholder={t(input.placeholderT)}
                            onChange={(e) => handleOnFieldChange(input.id, e.target.value)}
                            value={tempObject[input.id]}
                            error={errorsObj}
                            helperText={
                                (input.required &&
                                    error &&
                                    !tempObject[input.id] &&
                                    t('common:validations.mixed.required', { path: t(input.errorTextT) })) ||
                                (input.id === 'postalCode' &&
                                    error &&
                                    !postalCodeValid &&
                                    (!tempObject.postalCode || tempObject.postalCode.length < 3) &&
                                    t('common:validations.string.min', { path: t(input.errorTextT), min: 3 })) ||
                                (input.id === 'postalCode' &&
                                    error &&
                                    !postalCodeValid &&
                                    tempObject.postalCode &&
                                    tempObject?.postalCode.length > 20 &&
                                    t('common:validations.string.max', { path: t(input.errorTextT), max: 20 })) ||
                                (input.required &&
                                    input.id === 'city' &&
                                    error &&
                                    cityValid &&
                                    t('common:validations.string.max', { path: t(input.errorTextT), max: 80 })) ||
                                (input.required &&
                                    input.id === 'street' &&
                                    error &&
                                    streetValid &&
                                    t('common:validations.string.max', { path: t(input.errorTextT), max: 250 }))
                            }
                            endIcon={errorsObj ? IconsType.info_circle : undefined}
                            endIconClassName={classes.icon}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default FallBackFields;
