import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const usePreviousPathname = () => {
    const { pathname } = useLocation();
    const previousPathnameRef = useRef<string | null>(null);

    useEffect(() => {
        previousPathnameRef.current = pathname;
    }, [pathname]);

    return previousPathnameRef.current;
};

export default usePreviousPathname;
