import * as React from 'react';
import Typography from 'components/Typography';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import useStyles from '../fundedInvestingManualKycDialog.styles';
import { useTheme } from '@material-ui/core';
import SumsubWebSdk from '@sumsub/websdk-react';
import { mixPanelEventsTypes } from 'helpers/mixPanel';
import { AccessTokenResponse, AccountInfoParsed } from 'services/generatedClientFromSwagger/models';
import { user_userInfo } from 'store/user/selectors';
import { sumsub_getSumsubData, sumsub_requestApproved } from 'features/sumsub/store/actions';
import { sumsub_loadingState, sumsub_subsubData } from 'features/sumsub/store/selector';
import analyticsManager from 'helpers/analyticsManager';

export interface SumSubDialogStepProps {
    subsubData: AccessTokenResponse | undefined;
    loading: boolean;
    userInfo: AccountInfoParsed;
    uploadD_getSumsubData: () => Promise<string>;
    uploadD_requestApproved: () => void;
    onSumSubSubmission: () => void;
}

const mapStateToProps = (state) => ({
    subsubData: sumsub_subsubData(state),
    loading: sumsub_loadingState(state),
    userInfo: user_userInfo(state),
});

const mapDispatchToProps = {
    uploadD_getSumsubData: sumsub_getSumsubData,
    uploadD_requestApproved: sumsub_requestApproved,
};

const SumSubDialogStep: React.FunctionComponent<SumSubDialogStepProps> = (props) => {
    const { subsubData, userInfo, uploadD_getSumsubData, uploadD_requestApproved, onSumSubSubmission } = props;
    const {
        t,
        i18n: { language },
    } = useTranslation('prop');
    const classes = useStyles();
    const theme = useTheme();

    React.useEffect(() => {
        uploadD_getSumsubData();
    }, [uploadD_getSumsubData]);

    const messageHandler = (type, payload) => {
        analyticsManager.trackEvent(mixPanelEventsTypes.SUMSUB_EVENTS, { type, payload });
        console.log(type, payload);
        if (type === 'idCheck.onApplicantSubmitted' || type === 'idCheck.onApplicantResubmitted') {
            uploadD_requestApproved();
            onSumSubSubmission();
            // setRenewPlatformUserToSS();
        }
    };

    const errorHandler = (error) => {
        analyticsManager.trackEvent(mixPanelEventsTypes.SUMSUB_EVENTS, { type: 'error', error });
        console.error('WebSDK onError', error);
    };

    return (
        <>
            <Typography className={classes.sumSubTitle} variant="tLgSemibold" color="inherit">
                {t('checkout.sumSubDialog.title')}
            </Typography>
            <div className={classes.center}>
                <SumsubWebSdk
                    accessToken={subsubData?.accessToken || ' '}
                    expirationHandler={uploadD_getSumsubData}
                    config={{
                        lang: language, //language of WebSDK texts and comments (ISO 639-1 format)
                        email: userInfo.email || undefined,
                        theme: theme.palette.type,
                    }}
                    baseUrl={subsubData?.apiUrl}
                    options={{ addViewportTag: false, adaptIframeHeight: true }}
                    onMessage={messageHandler}
                    onError={errorHandler}
                />
            </div>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SumSubDialogStep));
