import Button from 'components/Button';
import Typography from 'components/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import useStyles from './quick-preview-message.styles';
import classNames from 'classnames';
import { NotificationLink } from 'services/generatedClientFromSwagger';
import { getDiffInDDHHMM } from 'helpers/getDiffInDDHHMM';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ToolTip from 'components/ToolTip';
import { dateWithTimeFilter } from 'helpers/dateFilter';
import Icon, { IconsType } from 'components/Icon';
import {
    lineButton,
    mapDispatchToProps,
    mapStateToProps,
    QuickPreviewMessageProps,
    textLines,
} from './quick-preview-message-scheme';
import { connect } from 'react-redux';
import config from 'config/common';
import { isValidUrl } from 'helpers/regex';

const limitWord = 15;

const QuickPreviewMessage: React.FunctionComponent<QuickPreviewMessageProps> = ({
    closeMenu,
    message,
    isActive,
    markNotification,
    handleKycDialogStateFromNotification,
    acceptChallengeTermsAndCondition,
}) => {
    const [showMoreState, setShowMoreState] = React.useState<boolean>(false);
    let url: URL | undefined;
    try {
        url = new URL(
            isValidUrl(message?.link?.url) ? message?.link?.url : `${window.location.origin}${message?.link?.url}`
        );
    } catch (e) {
        console.error(e);
    }
    const isManualKyc = url?.hash === '#manualKyc';

    const classes = useStyles();
    const { t } = useTranslation('notification');

    const handleOnMessageClick = (e) => {
        if (message && !message.markedAt) {
            markNotification && markNotification([message._id]);
        }
    };

    const showMore = (e) => {
        setShowMoreState(true);
    };

    const showLess = (e) => {
        setShowMoreState(false);
    };

    const handleButtonClick = () => {
        if (closeMenu) {
            closeMenu();
        }
    };

    const getLinkComponent = (link: NotificationLink) => {
        if (isManualKyc && config.featuresFlags['manualKyc']) {
            return (
                <Button
                    onClick={handleKycDialogStateFromNotification}
                    size="small"
                    endIcon={IconsType.arrow_back}
                    variant={'text'}
                    color={isActive ? 'default' : 'primary'}
                    className={classNames(classes.buttonDefault, classes.button)}
                >
                    {t(`links*|*${link.text || 'default'}`, {
                        keySeparator: '*|*',
                        defaultValue: link.text,
                    })}
                </Button>
            );
        } else {
            return (
                <Link to={`${url?.pathname}${url?.search}`}>
                    <Button
                        size="small"
                        endIcon={IconsType.arrow_back}
                        onClick={handleButtonClick}
                        variant={'text'}
                        color={isActive ? 'default' : 'primary'}
                        className={classNames(classes.buttonDefault, classes.button)}
                    >
                        {t(`links*|*${link.text || 'default'}`, {
                            keySeparator: '*|*',
                            defaultValue: link.text,
                        })}
                    </Button>
                </Link>
            );
        }
    };
    return (
        <div
            onClick={handleOnMessageClick}
            className={classNames(classes.messageWrapper, {
                [classes.messageWrapper__active]: isActive,
            })}
        >
            <div className={classes.title}>
                <div className={classes.iconContainer}>
                    <div className={classNames({ [classes.unreadMassage]: !message.markedAt })} />
                    {isManualKyc ? (
                        <Icon name={IconsType.shield_plus} className={classes.kycNotification} />
                    ) : (
                        <Icon name={IconsType[message.icon]} />
                    )}
                </div>

                <div className={classes.rightContainer}>
                    <div className={classes.headerContainer}>
                        <Typography variant="tSmallSemibold" className={classes.titleText}>
                            {t(`headers*|*${message.title}`, {
                                keySeparator: '*|*',
                                defaultValue: message.title,
                            })}
                        </Typography>

                        <div className={classes.dateText}>
                            <ToolTip title={dateWithTimeFilter(message.createdAt)}>
                                <span>
                                    <Typography variant="tXsMedium" className={classes.dateText}>
                                        {getDiffInDDHHMM(moment(message.createdAt), moment(), t, message.createdAt)}
                                    </Typography>
                                </span>
                            </ToolTip>
                        </div>
                    </div>

                    {message.content && (
                        <Typography className={classNames(classes.subText)} variant="tXsRegular">
                            {textLines(
                                t(`texts*|*${message.content}`, {
                                    keySeparator: '*|*',
                                    defaultValue: message.content,
                                }),
                                showMoreState,
                                limitWord
                            )}
                            {lineButton(
                                t(`texts*|*${message.content}`, {
                                    keySeparator: '*|*',
                                    defaultValue: message.content,
                                }),
                                showMoreState,
                                t,
                                classes,
                                showMore,
                                showLess,
                                limitWord
                            )}
                        </Typography>
                    )}
                    {message.link && getLinkComponent(message.link)}
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(QuickPreviewMessage));
