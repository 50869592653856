import * as React from 'react';
import { connect } from 'react-redux';
import {
    ClientSupportProps,
    mapDispatchToProps,
    mapStateToProps,
    clientSupportEmailFormInputsScheme,
    clientSupportEmailFormValidationsScheme,
    clientSupportPhoneFormInputsScheme,
    clientSupportPhoneFormValidationsScheme,
    clientSupportFormInputsScheme,
    clientSupportFormValidationsScheme,
} from './client-support.scheme';
import PageLayout from 'components/Layouts/PageLayout';
import Typography from 'components/Typography';
import { useTranslation } from 'react-i18next';
import useStyles from './client-support.styles';
import FormGenerator from 'components/FormGenerator';
import contactUsImage from 'assets/images/contactUs.png';
import { IconsType } from 'components/Icon';
import TabPanel from 'components/TabPanel/tab-panel.render';
import config from 'config/common';
import analyticsManager from 'helpers/analyticsManager';
import { mixPanelEventsTypes } from 'helpers/analyticsManager';

const tabs = [
    {
        tabT: 'client-support:tabs.email.tab_name',
        icon: IconsType.contact_email,
    },
    {
        tabT: 'client-support:tabs.phone.tab_name',
        icon: IconsType.contact_phone,
    },
];

export enum ContactTypes {
    EMAIL = 0,
    PHONE = 1,
}

const ClientSupport: React.FunctionComponent<ClientSupportProps> = (props) => {
    const { userInfo, contactUs } = props;
    const classes = useStyles({ contactUsImage });
    const { t } = useTranslation('client-support');

    const [contactUsLoading, setContactUsLoading] = React.useState<boolean>(false);
    const [resetFormKey, resetForm] = React.useState<number>(0);
    const [contactType, setContactType] = React.useState<ContactTypes>(ContactTypes.EMAIL);
    const [contactResStatus, setContactResStatus] = React.useState<'success' | 'failed' | undefined>();

    const handleOnPasswordChangeSubmit = async (values: any) => {
        const { yourEmail, phone, subject, yourMessage } = values;
        setContactUsLoading(true);
        const title = `Contact us - ${
            contactType === ContactTypes.EMAIL ? 'Email request' : 'Callback request'
        } - ${subject}`;
        const contactStatus = await contactUs({
            email: yourEmail,
            phoneNumber: phone,
            title,
            description: yourMessage,
        });
        analyticsManager.trackEventGeneric(mixPanelEventsTypes.CLIENT_SUPPORT_REQUESTED, { supportSubject: subject });
        setContactUsLoading(false);
        if (contactStatus) resetForm(resetFormKey + 1);
        setContactResStatus(contactStatus ? 'success' : 'failed');
    };

    const getFormGeneratorInputsAndValidations = () => {
        if (config.featuresFlags['clientSupportEmailOnly']) {
            return {
                inputs: clientSupportFormInputsScheme({
                    t,
                }),
                validations: clientSupportFormValidationsScheme,
            };
        }
        switch (contactType) {
            case ContactTypes.EMAIL:
                return {
                    inputs: clientSupportEmailFormInputsScheme({
                        userInfo,
                        t,
                    }),
                    validations: clientSupportEmailFormValidationsScheme,
                };
            case ContactTypes.PHONE:
                return {
                    inputs: clientSupportPhoneFormInputsScheme(t),
                    validations: clientSupportPhoneFormValidationsScheme,
                };
        }
    };

    return (
        <PageLayout fullWidth fullHeight infoData>
            <Typography variant="h1" color="inherit">
                {t('title')}
            </Typography>
            <div className={classes.contactUsImage} />
            {!config.featuresFlags['clientSupportEmailOnly'] && (
                <TabPanel
                    selected={contactType}
                    onChange={(e, value) => {
                        setContactType(value);
                        setContactResStatus(undefined);
                    }}
                    tabs={tabs.map((tab) => ({ label: t(tab.tabT), icon: tab.icon }))}
                />
            )}
            <FormGenerator
                key={resetFormKey + contactType}
                inputs={getFormGeneratorInputsAndValidations().inputs}
                validationsScheme={getFormGeneratorInputsAndValidations().validations}
                submitBtnT={t('client-support:sendBtn')}
                onSubmitClick={handleOnPasswordChangeSubmit}
                loading={contactUsLoading}
                submitBtnProps={{
                    status: contactResStatus,
                    statusDurationOverCallback: () => setContactResStatus(undefined),
                }}
            />
        </PageLayout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ClientSupport));
