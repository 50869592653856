import LoaderFullScreen from 'components/LoaderFullScreen';
import LoginRoute from 'components/PrivateRoute/LoginRoute';
import UserRoute from 'components/PrivateRoute/UserRoute';
import RegisterVerifyRoute from 'components/PrivateRoute/RegisterVerifyRoute';
import { Pages } from 'interfaces/main';
import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import config, { FeatureFlag } from 'config/common';

export interface MainRouteProps {
    path?: string | string[];
    exact?: boolean;
    component: any;
    isPrivate?: boolean;
    loginRoute?: boolean;
    RegisterVerifyRoute?: boolean;
    disableRouteByFeatureFlag?: string[];
    enableRouteByFeatureFlag?: string[];
    isPropRoute?: boolean;
}

const routes: Array<MainRouteProps> = [
    {
        component: React.lazy(() => import('scenes/Login')),
        path: `/${Pages.LOGIN}`,
        exact: true,
        isPrivate: false,
        loginRoute: true,
    },
    {
        component: React.lazy(() => import('scenes/Mte')),
        path: `/${Pages.MTE_COURSES}`,
        exact: false,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('scenes/RegisterVerification')),
        path: `/${Pages.PHONE_VERIFICATION}`,
        exact: true,
        isPrivate: true,
        RegisterVerifyRoute: true,
    },
    {
        component: React.lazy(() => import('scenes/RegisterVerification')),
        path: `/${Pages.EMAIL_VERIFICATION}`,
        exact: true,
        isPrivate: true,
        RegisterVerifyRoute: true,
    },
    {
        component: React.lazy(() => import('scenes/ForgotPassword')),
        path: `/${Pages.FORGOT_PASSWORD}`,
        exact: true,
        isPrivate: false,
        loginRoute: true,
    },
    {
        component: React.lazy(() => import('scenes/NewPassword')),
        path: `/${Pages.NEW_PASSWORD}`,
        exact: true,
        isPrivate: false,
        loginRoute: true,
    },
    {
        component: React.lazy(() => import('scenes/EmailConfirmation')),
        path: `/${Pages.EMAIL_CONFIRMATION}/:token?`,
        exact: true,
        isPrivate: false,
        loginRoute: false,
    },
    {
        component: React.lazy(() => import('scenes/Dashboard')),
        path: `/${Pages.DASHBOARD}`,
        exact: true,
        isPrivate: true,
        disableRouteByFeatureFlag: [FeatureFlag.PROP],
    },
    {
        component: React.lazy(() => import('scenes/MyWallet/MyWalletRouter')),
        path: `/${Pages.MY_WALLET}`,
        exact: false,
        isPrivate: true,
        disableRouteByFeatureFlag: [FeatureFlag.PROP],
    },
    {
        component: React.lazy(() => import('features/kyc')),
        path: `/${Pages.KYC}`,
        exact: false,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('features/upload-documents')),
        path: `/${Pages.UPLOAD_DOCUMENTS}`,
        exact: false,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('scenes/AccountSettings')),
        path: `/${Pages.ACCOUNT_SETTINGS}`,
        exact: true,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('scenes/ClientSupport')),
        path: `/${Pages.CLIENT_SUPPORT}`,
        exact: true,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('features/sumsub')),
        path: `/${Pages.SUMSUB_UPLOAD}`,
        exact: true,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('features/ReverseSolicitation')),
        path: `/${Pages.KYC_BROKER_17}`,
        exact: true,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('features/Notifications')),
        path: `/${Pages.NOTIFICATIONS}`,
        exact: false,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('scenes/TradingAccounts')),
        path: `/${Pages.TRADING_ACCOUNTS}`,
        exact: false,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('scenes/TradingAnalysis')),
        path: `/${Pages.TRADING_ANALYSIS}`,
        exact: false,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('scenes/Platform')),
        path: `/${Pages.PLATFORMS}`,
        exact: true,
        isPrivate: true,
        isPropRoute: true,
    },
    {
        component: React.lazy(() => import('scenes/Platform')),
        path: `/${Pages.PLATFORMS}/*`,
        exact: false,
        isPrivate: true,
        isPropRoute: true,
    },
    {
        component: React.lazy(() => import('features/prop')),
        path: [`/${Pages.PROP}`, `/${Pages.MY_WALLET}`],
        exact: false,
        isPrivate: true,
        enableRouteByFeatureFlag: [FeatureFlag.PROP],
    },
    {
        component: React.lazy(() => import('components/PrivateRoute/ToHomePage')),
        isPrivate: false,
    },
];

if (!config.featuresFlags?.blockNewRegistration) {
    routes.splice(1, 0, {
        component: React.lazy(() => import('scenes/Register')),
        path: `/${Pages.REGISTER}`,
        exact: true,
        isPrivate: false,
        loginRoute: true,
    });
}

if (config.featuresFlags?.referAndEarn) {
    routes.splice(1, 0, {
        component: React.lazy(() => import('scenes/ReferFriend')),
        path: `/${Pages.REFER_EARN}`,
        exact: false,
        isPrivate: true,
    });
}

const filteredRoutesByFeatureFlag = routes.filter((route) => {
    let isActive = true;
    if (route.disableRouteByFeatureFlag) {
        isActive = route.disableRouteByFeatureFlag.every((flag) => !config.featuresFlags[flag]);
    }
    if (route.enableRouteByFeatureFlag) {
        isActive = route.enableRouteByFeatureFlag.every((flag) => config.featuresFlags[flag]);
    }
    return isActive;
});

const MainRouter = () => {
    return (
        <Suspense fallback={<LoaderFullScreen />}>
            <Switch>
                {filteredRoutesByFeatureFlag.map((route, index) => {
                    let tempRoute = Object.assign({}, route);
                    if (route.RegisterVerifyRoute) return <RegisterVerifyRoute key={`Route-${index}`} {...tempRoute} />;
                    if (route.isPrivate) return <UserRoute key={`Route-${index}`} {...tempRoute} />;
                    if (route.loginRoute) return <LoginRoute key={`Route-${index}`} {...tempRoute} />;
                    return <Route key={`Route-${index}`} {...tempRoute} />;
                })}
            </Switch>
        </Suspense>
    );
};

export default MainRouter;
