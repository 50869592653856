import { setThemeToLocalStorage } from 'helpers/local_storage';
import { ThemeOptions } from 'interfaces/main';
import { RootState } from '..';
import { ESystemTypes } from './actionsTypes';
import { system_countriesList, system_currenciesList, system_theme } from './selectors';
import { history } from 'utils/history';
import axios from 'services/axios';
import { SnackbarProps } from 'components/Snackbar';
import { IFilterModel, IHeadCell } from 'components/EnhancedTable/interfaces';
import { user_promoCode } from 'store/user/selectors';
import LuckyOrange from 'services/LuckyOrange';
import { DeviceType } from 'hooks/useDeviceType';
import { mixPanelEventsTypes } from 'helpers/analyticsManager';
import analyticsManager from 'helpers/analyticsManager';

export const system_setSelectedPage = (newPage: string, replace: boolean = false): any => {
    return () => (replace ? history.replace(newPage) : history.push(newPage));
};

export const system_startLoader = () => ({
    type: ESystemTypes.START_LOADER,
});

export const system_stopLoader = () => ({
    type: ESystemTypes.STOP_LOADER,
});

export const system_resetApp = () => {
    LuckyOrange.destroy();
    return (dispatch) => {
        dispatch(resetApp());
    };
};

const resetApp = () => ({
    type: ESystemTypes.RESET_APP,
});

export const system_toggleTheme = () => {
    return (dispatch, getState) => {
        const state: RootState = getState();
        const currentTheme = system_theme(state);
        const newTheme = currentTheme === ThemeOptions.DARK ? ThemeOptions.LIGHT : ThemeOptions.DARK;
        setThemeToLocalStorage(newTheme);
        analyticsManager.trackEventGeneric(mixPanelEventsTypes.LIGHT_DARK_MODE_CHANGE, { lightDarkMode: newTheme });
        dispatch({
            type: ESystemTypes.TOGGLE_THEME,
            payload: newTheme,
        });
    };
};

export const system_toggleSideMenu = () => ({
    type: ESystemTypes.TOGGLE_SIDE_MENU,
});

export const system_getCountries = () => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: ESystemTypes.GET_COUNTRIES_START });
            const state: RootState = getState();
            const countries = system_countriesList(state);
            if (countries && countries.length > 0) {
                return dispatch({ type: ESystemTypes.GET_COUNTRIES_FAIL });
            }
            const res = await axios.CrmPublicApi.crmPublicControllerGetCountries({});
            const newCountries = res?.data?.getCountriesResult || [];
            dispatch({ type: ESystemTypes.GET_COUNTRIES_SUCCESS, payload: newCountries });
        } catch (error) {
            dispatch({ type: ESystemTypes.GET_COUNTRIES_FAIL });
        }
    };
};

export const system_getCurrencies = () => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: ESystemTypes.GET_CURRENCIES_START });
            const state: RootState = getState();
            const currencies = system_currenciesList(state);
            const promoCode = user_promoCode(state);
            if (currencies.real.length > 0 && currencies.demo.length > 0) {
                return dispatch({ type: ESystemTypes.GET_CURRENCIES_FAIL });
            }
            const res = await axios.UserApi.userControllerGetUserCurrencies(promoCode);
            const newCurrencies = res.data;
            dispatch({ type: ESystemTypes.GET_CURRENCIES_SUCCESS, payload: newCurrencies });
        } catch (error) {
            dispatch({ type: ESystemTypes.GET_CURRENCIES_FAIL });
        }
    };
};

export const system_openSnackbar = (snackbarObject: SnackbarProps) => {
    return async (dispatch) => {
        setTimeout(() => {
            dispatch({
                type: ESystemTypes.OPEN_SNACKBAR,
                payload: snackbarObject,
            });
        }, 100);
    };
};

export const system_closeSnackbar = () => ({
    type: ESystemTypes.CLOSE_SNACKBAR,
});
export const system_toggleEducationModal = () => ({
    type: ESystemTypes.TOGGLE_EDUCATION_POPUP,
});

export const downloadCsv = (
    filters: IFilterModel,
    data: any[],
    selectedItems: any,
    headers: IHeadCell[],
    filename: string
) => {
    return {
        type: ESystemTypes.DOWNLOAD_CSV,
    };
};

export const updateFilters = (filters: IFilterModel, dontSaveToLocal = false) => {
    return async (dispatch) => {
        dispatch({ type: ESystemTypes.UPDATE_FILTERS, payload: filters });
    };
};

export const getFiltersFromLocalStorage = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: ESystemTypes.UPDATE_FILTERS, payload: {} });
        } catch (error) {
            dispatch({ type: ESystemTypes.UPDATE_FILTERS, payload: {} });
        }
    };
};

export const scrollToTable = (newState) => ({
    type: ESystemTypes.SCROLL_TO_TABLE,
    payload: newState,
});

export const walkThroughCompleteAction = (completed) => ({
    type: ESystemTypes.COMPLETED_WALK_THROUGH,
    payload: completed,
});

export const system_toggleBottomNavBar = (toggle: boolean) => ({
    type: ESystemTypes.TOGGLE_BOTTOM_NAV_BAR,
    payload: toggle,
});

export const system_deviceTypeSize = (deviceSize: DeviceType) => ({
    type: ESystemTypes.DEVICE_SIZE,
    payload: deviceSize,
});
