import { Theme, makeStyles } from '@material-ui/core';
import { AlignItems, Direction, JustifyContent, Overflow } from './Stack.types';

const resolveSpacing = (theme: Theme, spacing: Array<number> | number) => {
    if (Array.isArray(spacing)) {
        return `${spacing.map((s) => theme.spacing(s)).join('rem ')}rem`;
    }
    return `${theme.spacing(spacing)}rem`;
};

interface StackProps {
    direction: Direction;
    alignItems: AlignItems;
    justifyContent: JustifyContent;
    spacing: Array<number> | number;
    fullHeight?: boolean;
    fullWidth?: boolean;
    padding: Array<number> | number;
    margin: Array<number> | number;
    flexWrap?: boolean;
    overflow: Overflow;
}

export default makeStyles((theme) => ({
    stackComponent: (props: StackProps) => ({
        position: 'relative',
        display: 'flex',
        flexDirection: props.direction,
        alignItems: props.alignItems,
        justifyContent: props.justifyContent,
        gap: resolveSpacing(theme, props.spacing),
        padding: resolveSpacing(theme, props.padding),
        margin: resolveSpacing(theme, props.margin),
        flexWrap: props.flexWrap ? 'wrap' : 'nowrap',
        overflow: props.overflow,

        ...(props.fullHeight && {
            height: '100%',
        }),

        ...(props.fullWidth && {
            width: '100%',
        }),
    }),
}));
