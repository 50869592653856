export enum EPlatformTypes {
    GET_IFRAME_URL = '[platform] get platform iframe',
    GET_IFRAME_URL_SUCCESS = '[platform] get platform iframe success',
    GET_IFRAME_URL_FAILED = '[platform] get platform iframe failed',

    GET_MTE_IFRAME_URL = '[platform] get mte iframe url',
    GET_MTE_IFRAME_URL_SUCCESS = '[platform] get mte iframe url success',
    GET_MTE_IFRAME_URL_FAILED = '[platform] get mte iframe url failed',

    RESET_STATE = '[platform] reset state',
}
