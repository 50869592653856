export const passwordRegex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^₪*)(_+=?.]{8,15}$/);
//eslint-disable-next-line
export const phoneRegex = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);

export const latinOnlyRegex = new RegExp(/^[A-Za-z _]+$/);

export const cardLast4DigitsRegex = new RegExp(/^[0-9]{4}$/);

export const emailRegex = new RegExp(
    //eslint-disable-next-line
    /^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/
);

export const emailRegexRegister = new RegExp(
    //eslint-disable-next-line
    /^[a-zA-Z0-9][a-zA-Z0-9.+_-]*[a-zA-Z0-9]+@[a-z0-9-]+\.[a-z]{2,4}$/
);

export const latinNumbersAndSymbolsRegex = new RegExp(
    //eslint-disable-next-line
    /^[~` !@#$%^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/
);

export const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$',
        'i'
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
};
