import { EagerImportedSVGModules } from 'interfaces/main';
const imagesGlob = import.meta.glob<EagerImportedSVGModules>('/src/assets/images/*.png', { eager: true });
const instrumentsGlob = import.meta.glob<EagerImportedSVGModules>('/src/assets/instruments/*.svg', { eager: true });

export const getImages = () => {
    const images = Object.keys(imagesGlob).reduce((acc, key) => {
        const newKey = key.split('/').pop()?.split('.').shift() || '';
        acc[newKey] = imagesGlob[key].default;
        return acc;
    }, {});
    return images;
};

export const getInstruments = () => {
    const instruments = Object.keys(instrumentsGlob).reduce((acc, key) => {
        const newKey = key.split('/').pop()?.split('.').shift() || '';
        acc[newKey] = instrumentsGlob[key].default;
        return acc;
    }, {});
    return instruments;
};
