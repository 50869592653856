/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AcceptChallengeTermsRequest } from '../models';
// @ts-ignore
import { CreateMonetaryTransactionResponse } from '../models';
// @ts-ignore
import { DepositWireResponse } from '../models';
// @ts-ignore
import { FundingWithdrawalResponse } from '../models';
// @ts-ignore
import { GetChallengeValidateWithdrawalResponse } from '../models';
// @ts-ignore
import { GetChallengeWithdrawalMonetaryTransaction } from '../models';
// @ts-ignore
import { GetChallengesResponse } from '../models';
// @ts-ignore
import { GetFundingWithdrawalRequest } from '../models';
// @ts-ignore
import { GetMonetaryTransactionsAdditionalInfoRequest } from '../models';
// @ts-ignore
import { GetMonetaryTransactionsAdditionalInfoResponse } from '../models';
// @ts-ignore
import { GetPlansResponse } from '../models';
// @ts-ignore
import { GetPromoDiscountCodesResponse } from '../models';
// @ts-ignore
import { ManualKycRequest } from '../models';
// @ts-ignore
import { ManualKycResponse } from '../models';
// @ts-ignore
import { MonetaryTransaction } from '../models';
// @ts-ignore
import { NewChallengeOptionsResponse } from '../models';
// @ts-ignore
import { PaymentIframeResponse } from '../models';
// @ts-ignore
import { PropDepositWireRequest } from '../models';
// @ts-ignore
import { PropFreeTrialRequest } from '../models';
// @ts-ignore
import { PropFullDiscountRequest } from '../models';
// @ts-ignore
import { PropKeeperPaymentRequest } from '../models';
// @ts-ignore
import { PropPaymentRequest } from '../models';
// @ts-ignore
import { PropPendingPaymentRequest } from '../models';
// @ts-ignore
import { ResultInfo } from '../models';
// @ts-ignore
import { ValidateDiscountCodeRequest } from '../models';
// @ts-ignore
import { ValidateDiscountCodeResponse } from '../models';
/**
 * PropApi - axios parameter creator
 * @export
 */
export const PropApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {AcceptChallengeTermsRequest} acceptChallengeTermsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerAcceptTermsAndConditions: async (
            acceptChallengeTermsRequest: AcceptChallengeTermsRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'acceptChallengeTermsRequest' is not null or undefined
            assertParamExists(
                'propApiControllerAcceptTermsAndConditions',
                'acceptChallengeTermsRequest',
                acceptChallengeTermsRequest
            );
            const localVarPath = `/api/prop/acceptTermsAndConditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                acceptChallengeTermsRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PropFullDiscountRequest} propFullDiscountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerCreateFullDiscountPlan: async (
            propFullDiscountRequest: PropFullDiscountRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'propFullDiscountRequest' is not null or undefined
            assertParamExists(
                'propApiControllerCreateFullDiscountPlan',
                'propFullDiscountRequest',
                propFullDiscountRequest
            );
            const localVarPath = `/api/prop/propCreateFullDiscountPlan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                propFullDiscountRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PropFreeTrialRequest} propFreeTrialRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerFreeTrialPlanPayment: async (
            propFreeTrialRequest: PropFreeTrialRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'propFreeTrialRequest' is not null or undefined
            assertParamExists('propApiControllerFreeTrialPlanPayment', 'propFreeTrialRequest', propFreeTrialRequest);
            const localVarPath = `/api/prop/freeTrialPlanPayment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                propFreeTrialRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {GetMonetaryTransactionsAdditionalInfoRequest} getMonetaryTransactionsAdditionalInfoRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerGetAdditionalInfoFromMonetaryTransactions: async (
            getMonetaryTransactionsAdditionalInfoRequest: GetMonetaryTransactionsAdditionalInfoRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'getMonetaryTransactionsAdditionalInfoRequest' is not null or undefined
            assertParamExists(
                'propApiControllerGetAdditionalInfoFromMonetaryTransactions',
                'getMonetaryTransactionsAdditionalInfoRequest',
                getMonetaryTransactionsAdditionalInfoRequest
            );
            const localVarPath = `/api/prop/getAdditionalInfoFromMonetaryTransactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                getMonetaryTransactionsAdditionalInfoRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} tpId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerGetChallengeIframeUrl: async (tpId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tpId' is not null or undefined
            assertParamExists('propApiControllerGetChallengeIframeUrl', 'tpId', tpId);
            const localVarPath = `/api/prop/getChallengeIframeUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (tpId !== undefined) {
                localVarQueryParameter['tpId'] = tpId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {GetChallengeWithdrawalMonetaryTransaction} getChallengeWithdrawalMonetaryTransaction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerGetChallengeValidateWithdrawal: async (
            getChallengeWithdrawalMonetaryTransaction: GetChallengeWithdrawalMonetaryTransaction,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'getChallengeWithdrawalMonetaryTransaction' is not null or undefined
            assertParamExists(
                'propApiControllerGetChallengeValidateWithdrawal',
                'getChallengeWithdrawalMonetaryTransaction',
                getChallengeWithdrawalMonetaryTransaction
            );
            const localVarPath = `/api/prop/getChallengeValidateWithdrawal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                getChallengeWithdrawalMonetaryTransaction,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {GetChallengeWithdrawalMonetaryTransaction} getChallengeWithdrawalMonetaryTransaction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerGetChallengeWithdrawal: async (
            getChallengeWithdrawalMonetaryTransaction: GetChallengeWithdrawalMonetaryTransaction,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'getChallengeWithdrawalMonetaryTransaction' is not null or undefined
            assertParamExists(
                'propApiControllerGetChallengeWithdrawal',
                'getChallengeWithdrawalMonetaryTransaction',
                getChallengeWithdrawalMonetaryTransaction
            );
            const localVarPath = `/api/prop/getChallengeWithdrawal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                getChallengeWithdrawalMonetaryTransaction,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerGetChallenges: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/prop/getChallenges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerGetPlans: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/prop/getPlans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerGetPromoDiscountCodes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/prop/getPromoDiscountCodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ManualKycRequest} manualKycRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerManualKyc: async (
            manualKycRequest: ManualKycRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'manualKycRequest' is not null or undefined
            assertParamExists('propApiControllerManualKyc', 'manualKycRequest', manualKycRequest);
            const localVarPath = `/api/prop/manualKyc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                manualKycRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerNewChallengeOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/prop/newChallengeOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PropDepositWireRequest} propDepositWireRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerPropDepositWire: async (
            propDepositWireRequest: PropDepositWireRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'propDepositWireRequest' is not null or undefined
            assertParamExists('propApiControllerPropDepositWire', 'propDepositWireRequest', propDepositWireRequest);
            const localVarPath = `/api/prop/propDepositWire`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                propDepositWireRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PropPaymentRequest} propPaymentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerPropPayment: async (
            propPaymentRequest: PropPaymentRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'propPaymentRequest' is not null or undefined
            assertParamExists('propApiControllerPropPayment', 'propPaymentRequest', propPaymentRequest);
            const localVarPath = `/api/prop/propPayment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                propPaymentRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {GetFundingWithdrawalRequest} getFundingWithdrawalRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerPropPayout: async (
            getFundingWithdrawalRequest: GetFundingWithdrawalRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'getFundingWithdrawalRequest' is not null or undefined
            assertParamExists(
                'propApiControllerPropPayout',
                'getFundingWithdrawalRequest',
                getFundingWithdrawalRequest
            );
            const localVarPath = `/api/prop/propPayout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                getFundingWithdrawalRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PropPendingPaymentRequest} propPendingPaymentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerPropPendingPayment: async (
            propPendingPaymentRequest: PropPendingPaymentRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'propPendingPaymentRequest' is not null or undefined
            assertParamExists(
                'propApiControllerPropPendingPayment',
                'propPendingPaymentRequest',
                propPendingPaymentRequest
            );
            const localVarPath = `/api/prop/propPendingPayment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                propPendingPaymentRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PropKeeperPaymentRequest} propKeeperPaymentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerPurchaseChallengeKeeperIframeUrl: async (
            propKeeperPaymentRequest: PropKeeperPaymentRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'propKeeperPaymentRequest' is not null or undefined
            assertParamExists(
                'propApiControllerPurchaseChallengeKeeperIframeUrl',
                'propKeeperPaymentRequest',
                propKeeperPaymentRequest
            );
            const localVarPath = `/api/prop/purchaseChallengeKeeperIframeUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                propKeeperPaymentRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ValidateDiscountCodeRequest} validateDiscountCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerValidateDiscountCode: async (
            validateDiscountCodeRequest: ValidateDiscountCodeRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'validateDiscountCodeRequest' is not null or undefined
            assertParamExists(
                'propApiControllerValidateDiscountCode',
                'validateDiscountCodeRequest',
                validateDiscountCodeRequest
            );
            const localVarPath = `/api/prop/validateDiscountCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                validateDiscountCodeRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * PropApi - functional programming interface
 * @export
 */
export const PropApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = PropApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {AcceptChallengeTermsRequest} acceptChallengeTermsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerAcceptTermsAndConditions(
            acceptChallengeTermsRequest: AcceptChallengeTermsRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerAcceptTermsAndConditions(
                acceptChallengeTermsRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {PropFullDiscountRequest} propFullDiscountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerCreateFullDiscountPlan(
            propFullDiscountRequest: PropFullDiscountRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerCreateFullDiscountPlan(
                propFullDiscountRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {PropFreeTrialRequest} propFreeTrialRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerFreeTrialPlanPayment(
            propFreeTrialRequest: PropFreeTrialRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerFreeTrialPlanPayment(
                propFreeTrialRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {GetMonetaryTransactionsAdditionalInfoRequest} getMonetaryTransactionsAdditionalInfoRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerGetAdditionalInfoFromMonetaryTransactions(
            getMonetaryTransactionsAdditionalInfoRequest: GetMonetaryTransactionsAdditionalInfoRequest,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMonetaryTransactionsAdditionalInfoResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.propApiControllerGetAdditionalInfoFromMonetaryTransactions(
                    getMonetaryTransactionsAdditionalInfoRequest,
                    options
                );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} tpId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerGetChallengeIframeUrl(
            tpId: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerGetChallengeIframeUrl(
                tpId,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {GetChallengeWithdrawalMonetaryTransaction} getChallengeWithdrawalMonetaryTransaction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerGetChallengeValidateWithdrawal(
            getChallengeWithdrawalMonetaryTransaction: GetChallengeWithdrawalMonetaryTransaction,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetChallengeValidateWithdrawalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerGetChallengeValidateWithdrawal(
                getChallengeWithdrawalMonetaryTransaction,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {GetChallengeWithdrawalMonetaryTransaction} getChallengeWithdrawalMonetaryTransaction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerGetChallengeWithdrawal(
            getChallengeWithdrawalMonetaryTransaction: GetChallengeWithdrawalMonetaryTransaction,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonetaryTransaction>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerGetChallengeWithdrawal(
                getChallengeWithdrawalMonetaryTransaction,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerGetChallenges(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetChallengesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerGetChallenges(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerGetPlans(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPlansResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerGetPlans(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerGetPromoDiscountCodes(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPromoDiscountCodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerGetPromoDiscountCodes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {ManualKycRequest} manualKycRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerManualKyc(
            manualKycRequest: ManualKycRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManualKycResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerManualKyc(
                manualKycRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerNewChallengeOptions(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewChallengeOptionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerNewChallengeOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {PropDepositWireRequest} propDepositWireRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerPropDepositWire(
            propDepositWireRequest: PropDepositWireRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepositWireResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerPropDepositWire(
                propDepositWireRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {PropPaymentRequest} propPaymentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerPropPayment(
            propPaymentRequest: PropPaymentRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentIframeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerPropPayment(
                propPaymentRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {GetFundingWithdrawalRequest} getFundingWithdrawalRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerPropPayout(
            getFundingWithdrawalRequest: GetFundingWithdrawalRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FundingWithdrawalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerPropPayout(
                getFundingWithdrawalRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {PropPendingPaymentRequest} propPendingPaymentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerPropPendingPayment(
            propPendingPaymentRequest: PropPendingPaymentRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateMonetaryTransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerPropPendingPayment(
                propPendingPaymentRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {PropKeeperPaymentRequest} propKeeperPaymentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerPurchaseChallengeKeeperIframeUrl(
            propKeeperPaymentRequest: PropKeeperPaymentRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentIframeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerPurchaseChallengeKeeperIframeUrl(
                propKeeperPaymentRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {ValidateDiscountCodeRequest} validateDiscountCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerValidateDiscountCode(
            validateDiscountCodeRequest: ValidateDiscountCodeRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateDiscountCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerValidateDiscountCode(
                validateDiscountCodeRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * PropApi - factory interface
 * @export
 */
export const PropApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropApiFp(configuration);
    return {
        /**
         *
         * @param {AcceptChallengeTermsRequest} acceptChallengeTermsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerAcceptTermsAndConditions(
            acceptChallengeTermsRequest: AcceptChallengeTermsRequest,
            options?: any
        ): AxiosPromise<ResultInfo> {
            return localVarFp
                .propApiControllerAcceptTermsAndConditions(acceptChallengeTermsRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PropFullDiscountRequest} propFullDiscountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerCreateFullDiscountPlan(
            propFullDiscountRequest: PropFullDiscountRequest,
            options?: any
        ): AxiosPromise<ResultInfo> {
            return localVarFp
                .propApiControllerCreateFullDiscountPlan(propFullDiscountRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PropFreeTrialRequest} propFreeTrialRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerFreeTrialPlanPayment(
            propFreeTrialRequest: PropFreeTrialRequest,
            options?: any
        ): AxiosPromise<ResultInfo> {
            return localVarFp
                .propApiControllerFreeTrialPlanPayment(propFreeTrialRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {GetMonetaryTransactionsAdditionalInfoRequest} getMonetaryTransactionsAdditionalInfoRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerGetAdditionalInfoFromMonetaryTransactions(
            getMonetaryTransactionsAdditionalInfoRequest: GetMonetaryTransactionsAdditionalInfoRequest,
            options?: any
        ): AxiosPromise<GetMonetaryTransactionsAdditionalInfoResponse> {
            return localVarFp
                .propApiControllerGetAdditionalInfoFromMonetaryTransactions(
                    getMonetaryTransactionsAdditionalInfoRequest,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} tpId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerGetChallengeIframeUrl(tpId: string, options?: any): AxiosPromise<void> {
            return localVarFp
                .propApiControllerGetChallengeIframeUrl(tpId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {GetChallengeWithdrawalMonetaryTransaction} getChallengeWithdrawalMonetaryTransaction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerGetChallengeValidateWithdrawal(
            getChallengeWithdrawalMonetaryTransaction: GetChallengeWithdrawalMonetaryTransaction,
            options?: any
        ): AxiosPromise<GetChallengeValidateWithdrawalResponse> {
            return localVarFp
                .propApiControllerGetChallengeValidateWithdrawal(getChallengeWithdrawalMonetaryTransaction, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {GetChallengeWithdrawalMonetaryTransaction} getChallengeWithdrawalMonetaryTransaction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerGetChallengeWithdrawal(
            getChallengeWithdrawalMonetaryTransaction: GetChallengeWithdrawalMonetaryTransaction,
            options?: any
        ): AxiosPromise<Array<MonetaryTransaction>> {
            return localVarFp
                .propApiControllerGetChallengeWithdrawal(getChallengeWithdrawalMonetaryTransaction, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerGetChallenges(options?: any): AxiosPromise<GetChallengesResponse> {
            return localVarFp.propApiControllerGetChallenges(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerGetPlans(options?: any): AxiosPromise<GetPlansResponse> {
            return localVarFp.propApiControllerGetPlans(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerGetPromoDiscountCodes(options?: any): AxiosPromise<GetPromoDiscountCodesResponse> {
            return localVarFp
                .propApiControllerGetPromoDiscountCodes(options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ManualKycRequest} manualKycRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerManualKyc(manualKycRequest: ManualKycRequest, options?: any): AxiosPromise<ManualKycResponse> {
            return localVarFp
                .propApiControllerManualKyc(manualKycRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerNewChallengeOptions(options?: any): AxiosPromise<NewChallengeOptionsResponse> {
            return localVarFp.propApiControllerNewChallengeOptions(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PropDepositWireRequest} propDepositWireRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerPropDepositWire(
            propDepositWireRequest: PropDepositWireRequest,
            options?: any
        ): AxiosPromise<DepositWireResponse> {
            return localVarFp
                .propApiControllerPropDepositWire(propDepositWireRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PropPaymentRequest} propPaymentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerPropPayment(
            propPaymentRequest: PropPaymentRequest,
            options?: any
        ): AxiosPromise<PaymentIframeResponse> {
            return localVarFp
                .propApiControllerPropPayment(propPaymentRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {GetFundingWithdrawalRequest} getFundingWithdrawalRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerPropPayout(
            getFundingWithdrawalRequest: GetFundingWithdrawalRequest,
            options?: any
        ): AxiosPromise<FundingWithdrawalResponse> {
            return localVarFp
                .propApiControllerPropPayout(getFundingWithdrawalRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PropPendingPaymentRequest} propPendingPaymentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerPropPendingPayment(
            propPendingPaymentRequest: PropPendingPaymentRequest,
            options?: any
        ): AxiosPromise<CreateMonetaryTransactionResponse> {
            return localVarFp
                .propApiControllerPropPendingPayment(propPendingPaymentRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PropKeeperPaymentRequest} propKeeperPaymentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerPurchaseChallengeKeeperIframeUrl(
            propKeeperPaymentRequest: PropKeeperPaymentRequest,
            options?: any
        ): AxiosPromise<PaymentIframeResponse> {
            return localVarFp
                .propApiControllerPurchaseChallengeKeeperIframeUrl(propKeeperPaymentRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ValidateDiscountCodeRequest} validateDiscountCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerValidateDiscountCode(
            validateDiscountCodeRequest: ValidateDiscountCodeRequest,
            options?: any
        ): AxiosPromise<ValidateDiscountCodeResponse> {
            return localVarFp
                .propApiControllerValidateDiscountCode(validateDiscountCodeRequest, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropApi - object-oriented interface
 * @export
 * @class PropApi
 * @extends {BaseAPI}
 */
export class PropApi extends BaseAPI {
    /**
     *
     * @param {AcceptChallengeTermsRequest} acceptChallengeTermsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerAcceptTermsAndConditions(
        acceptChallengeTermsRequest: AcceptChallengeTermsRequest,
        options?: any
    ) {
        return PropApiFp(this.configuration)
            .propApiControllerAcceptTermsAndConditions(acceptChallengeTermsRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PropFullDiscountRequest} propFullDiscountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerCreateFullDiscountPlan(propFullDiscountRequest: PropFullDiscountRequest, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerCreateFullDiscountPlan(propFullDiscountRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PropFreeTrialRequest} propFreeTrialRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerFreeTrialPlanPayment(propFreeTrialRequest: PropFreeTrialRequest, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerFreeTrialPlanPayment(propFreeTrialRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {GetMonetaryTransactionsAdditionalInfoRequest} getMonetaryTransactionsAdditionalInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerGetAdditionalInfoFromMonetaryTransactions(
        getMonetaryTransactionsAdditionalInfoRequest: GetMonetaryTransactionsAdditionalInfoRequest,
        options?: any
    ) {
        return PropApiFp(this.configuration)
            .propApiControllerGetAdditionalInfoFromMonetaryTransactions(
                getMonetaryTransactionsAdditionalInfoRequest,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} tpId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerGetChallengeIframeUrl(tpId: string, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerGetChallengeIframeUrl(tpId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {GetChallengeWithdrawalMonetaryTransaction} getChallengeWithdrawalMonetaryTransaction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerGetChallengeValidateWithdrawal(
        getChallengeWithdrawalMonetaryTransaction: GetChallengeWithdrawalMonetaryTransaction,
        options?: any
    ) {
        return PropApiFp(this.configuration)
            .propApiControllerGetChallengeValidateWithdrawal(getChallengeWithdrawalMonetaryTransaction, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {GetChallengeWithdrawalMonetaryTransaction} getChallengeWithdrawalMonetaryTransaction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerGetChallengeWithdrawal(
        getChallengeWithdrawalMonetaryTransaction: GetChallengeWithdrawalMonetaryTransaction,
        options?: any
    ) {
        return PropApiFp(this.configuration)
            .propApiControllerGetChallengeWithdrawal(getChallengeWithdrawalMonetaryTransaction, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerGetChallenges(options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerGetChallenges(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerGetPlans(options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerGetPlans(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerGetPromoDiscountCodes(options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerGetPromoDiscountCodes(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ManualKycRequest} manualKycRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerManualKyc(manualKycRequest: ManualKycRequest, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerManualKyc(manualKycRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerNewChallengeOptions(options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerNewChallengeOptions(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PropDepositWireRequest} propDepositWireRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerPropDepositWire(propDepositWireRequest: PropDepositWireRequest, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerPropDepositWire(propDepositWireRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PropPaymentRequest} propPaymentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerPropPayment(propPaymentRequest: PropPaymentRequest, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerPropPayment(propPaymentRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {GetFundingWithdrawalRequest} getFundingWithdrawalRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerPropPayout(getFundingWithdrawalRequest: GetFundingWithdrawalRequest, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerPropPayout(getFundingWithdrawalRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PropPendingPaymentRequest} propPendingPaymentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerPropPendingPayment(propPendingPaymentRequest: PropPendingPaymentRequest, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerPropPendingPayment(propPendingPaymentRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PropKeeperPaymentRequest} propKeeperPaymentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerPurchaseChallengeKeeperIframeUrl(
        propKeeperPaymentRequest: PropKeeperPaymentRequest,
        options?: any
    ) {
        return PropApiFp(this.configuration)
            .propApiControllerPurchaseChallengeKeeperIframeUrl(propKeeperPaymentRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ValidateDiscountCodeRequest} validateDiscountCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerValidateDiscountCode(
        validateDiscountCodeRequest: ValidateDiscountCodeRequest,
        options?: any
    ) {
        return PropApiFp(this.configuration)
            .propApiControllerValidateDiscountCode(validateDiscountCodeRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
