import { Dialog as DialogMat, DialogProps as DialogPropsMat } from '@material-ui/core';
import Button, { ButtonProps } from 'components/Button';
import Icon, { IconsType } from 'components/Icon';
import Typography from 'components/Typography';
import React, { useEffect } from 'react';
import useStyles from './dialog.styles';
import { Link } from 'react-router-dom';
import analyticsManager from 'helpers/analyticsManager';
import { mixPanelEventsTypes } from 'helpers/mixPanel';

export interface DialogProps extends DialogPropsMat {
    onClose?: (event: any, reason: 'backdropClick' | 'escapeKeyDown' | 'closeBtn') => void;
    title?: string;
    buttons?: {
        btnOne: ButtonProps;
        btnTwo?: ButtonProps;
        btnThree?: ButtonProps;
    };
    linkButton?: {
        newTab: boolean;
        href: string;
        text: string;
        buttonProps?: ButtonProps;
    };
    helperText?: React.ReactNode;
    errorMsg?: React.ReactNode;
    disableMaxWidth?: boolean;
    maxWidthStr?: string;
    disablePadding?: boolean;
    disableCloseButton?: boolean;
    fullHeight?: boolean;
    closeButtonIcon?: {
        icon: IconsType;
        className?: string;
    };
    eventLogName?: string;
}

const Dialog: React.FunctionComponent<DialogProps> = (props) => {
    const {
        onClose,
        open,
        title,
        children,
        buttons,
        linkButton,
        helperText,
        errorMsg,
        fullWidth = false,
        disableMaxWidth = false,
        maxWidthStr,
        disablePadding,
        disableCloseButton,
        fullHeight,
        eventLogName,
        closeButtonIcon = { icon: IconsType.close, className: undefined },
        ...rest
    } = props;
    const classes = useStyles({ fullWidth, disableMaxWidth, maxWidthStr, disablePadding, fullHeight });

    const handleOnClose = (event: any, reason: 'backdropClick' | 'escapeKeyDown' | 'closeBtn') => {
        onClose && onClose(event, reason);
    };

    useEffect(() => {
        if (open) analyticsManager.trackEventGeneric(mixPanelEventsTypes.POPUP, { popup: eventLogName || title });
    }, [open]);

    return (
        <DialogMat
            {...rest}
            fullWidth={fullWidth}
            open={open}
            onClose={handleOnClose}
            classes={{ paper: classes.settingPaper, ...props.classes }}
        >
            <div className={classes.wrapper} id="dialog_wrapper">
                {!disableCloseButton && (
                    <Button
                        variant="text"
                        color="inherit"
                        onClick={(e) => handleOnClose(e, 'closeBtn')}
                        className={classes.closeButton}
                    >
                        <Icon name={closeButtonIcon.icon} className={closeButtonIcon.className} />
                    </Button>
                )}
                {title && <Typography variant="h4">{title}</Typography>}
                <div className={classes.content}>{children}</div>
                {(buttons || errorMsg || helperText || linkButton) && (
                    <div className={classes.bottomSection}>
                        {helperText && !errorMsg && (
                            <div className={classes.helperTextWrapper}>
                                <Typography variant="body2" color="textSecondary">
                                    {helperText}
                                </Typography>
                            </div>
                        )}
                        {errorMsg && (
                            <div className={classes.errorMsgWrapper}>
                                <Icon name={IconsType.warning} />
                                <Typography variant="body1" color="error">
                                    {errorMsg}
                                </Typography>
                            </div>
                        )}
                        {buttons && (
                            <div className={classes.btnsWrapper}>
                                <Button classes={{ root: classes.btn }} {...buttons.btnOne} />
                                {buttons.btnTwo && <Button classes={{ root: classes.btn }} {...buttons.btnTwo} />}
                                {buttons.btnThree && <Button classes={{ root: classes.btn }} {...buttons.btnThree} />}
                            </div>
                        )}
                        {linkButton &&
                            (linkButton.newTab ? (
                                <a
                                    href={linkButton.href}
                                    target={'_blank'}
                                    rel="noopener noreferrer"
                                    className={classes.link}
                                >
                                    <Button
                                        classes={{ root: classes.btn }}
                                        variant="contained"
                                        color="primary"
                                        {...linkButton.buttonProps}
                                    >
                                        {linkButton.text}
                                    </Button>
                                </a>
                            ) : (
                                <Link to={linkButton.href} className={classes.link} target={'_self'}>
                                    <Button
                                        classes={{ root: classes.btn }}
                                        variant="contained"
                                        color="primary"
                                        {...linkButton.buttonProps}
                                    >
                                        {linkButton.text}
                                    </Button>
                                </Link>
                            ))}
                    </div>
                )}
            </div>
        </DialogMat>
    );
};

export default Dialog;
